import { adminRole } from "../../app/store/reducers/roles/index.D";
import getBrand from "../../app/helpers/getBrand";
import { useAppSelector } from "./redux.hook";


const useOperatedOrganization = () => {
	const brand = getBrand();

  const allOrganizations = useAppSelector(state => state.organizations.allOrganizationsList);
  const adminAuthed = useAppSelector(state => state.admin.adminAuthed);
  const currentOrganization = useAppSelector(state => state.organizations.organization);

  const availableOrganizations = useAppSelector(state => state.admin.adminAuthed?.organizations);

  const org = (allOrganizations?.length ? allOrganizations : availableOrganizations)?.find(org => org._id === brand);

  if(!org && adminAuthed?.roles.map(role => role.role).includes(adminRole.sysAdmin)) {
    return currentOrganization;
  }

  return org;
}

export default useOperatedOrganization;