// AXIOS
import { apiClient } from "../../clients/api.client";
// HELPERS
import getBrand from "../../helpers/getBrand";
// TYPES
import {IGetBalance, IDemoFundingAccount} from "../../store/reducers/balances/index.D";
// HELPERS
import objectToURL from "../../helpers/objectToURL";


const { get } = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default ({
    getCustomerBalances: ({id,params}:IGetBalance):Promise<string> => get(
        `${URL()}/customers/${id}/balances?${objectToURL(params)}`),
    getBusinessBalances: ({id,params}:IGetBalance):Promise<string> => get(
        `${URL()}/business/${id}/balances?${objectToURL(params)}`),
    customerDemoFunding: ({amount, accountId}: IDemoFundingAccount) =>
    get(`${URL()}/accounts/${accountId}/funding/${amount}`)
});