// CORE
import { createAsyncThunk } from "@reduxjs/toolkit";
// HELPERS
import { storeAddErrorNotification } from "../../../helpers/errors";
// SERVICES
import marginFeeService from "../../../../app/services/marginsFees";
import { ICreateMarginFeePayload } from "./index.D";


export default {
  create: createAsyncThunk(
    "marginFee/create",
    async (data: ICreateMarginFeePayload, thunkAPI) =>
      await storeAddErrorNotification(
        () => marginFeeService.create(data),
        thunkAPI
      )
  ),
  update: createAsyncThunk(
    "marginFee/update",
    async (
      { id, data }: { id: string; data: Partial<ICreateMarginFeePayload> },
      thunkAPI
    ) =>
      await storeAddErrorNotification(
        () => marginFeeService.update(id, data),
        thunkAPI
      )
  ),

  delete: createAsyncThunk(
    "marginFee/delete",
    async (id: string, thunkAPI) =>
      await storeAddErrorNotification(
        () => marginFeeService.delete(id),
        thunkAPI
      )
  ),

  readAccountPlatformFees: createAsyncThunk(
    "accountPlatformFees/read",
    async (params: Record<string, string>, thunkAPI) =>
      await storeAddErrorNotification(
        () => marginFeeService.readAccountPlatformFees(params),
        thunkAPI
      )
  ),

  readAccountConverionPair: createAsyncThunk(
    "accountConverionPair/read",
    async (params: Record<string, string>, thunkAPI) =>
      await storeAddErrorNotification(
        () => marginFeeService.readAccountConverionPair(params),
        thunkAPI
      )
  ),

  readFiatConversionGlobal: createAsyncThunk(
    "fiatConversionGlobal/read",
    async (params: Record<string, string>, thunkAPI) =>
      await storeAddErrorNotification(
        () => marginFeeService.readFiatConversionGlobal(params),
        thunkAPI
      )
  ),

  readAssetFees: createAsyncThunk(
    "assetFees/read",
    async (params: Record<string, string>, thunkAPI) =>
      await storeAddErrorNotification(
        () => marginFeeService.readAssetFees(params),
        thunkAPI
      )
  ),

  readAssetTraidingPairFees: createAsyncThunk(
    "assetTraidingPairFees/read",
    async (params: Record<string, string>, thunkAPI) =>
      await storeAddErrorNotification(
        () => marginFeeService.readAssetTraidingPairFees(params),
        thunkAPI
      )
  ),

  readAssetTraidingGlobalFees: createAsyncThunk(
    "assetTraidingGlobalFees/read",
    async (params: Record<string, string>, thunkAPI) =>
      await storeAddErrorNotification(
        () => marginFeeService.readAssetTraidingGlobalFees(params),
        thunkAPI
      )
  ),

  readConversionCryptoPair: createAsyncThunk(
    "conversionCryptoPair/read",
    async (params: Record<string, string>, thunkAPI) =>
      await storeAddErrorNotification(
        () => marginFeeService.readConversionCryptoPair(params),
        thunkAPI
      )
  ),

  readConversionCryptoGlobal: createAsyncThunk(
    "conversionCryptoGlobal/read",
    async (params: Record<string, string>, thunkAPI) =>
      await storeAddErrorNotification(
        () => marginFeeService.readConversionCryptoGlobal(params),
        thunkAPI
      )
  ),

  readTransferFees: createAsyncThunk(
    "transferFees/read",
    async (params: Record<string, string>, thunkAPI) =>
      await storeAddErrorNotification(
        () => marginFeeService.readTransferFees(params),
        thunkAPI
      )
  ),
};
