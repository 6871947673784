// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// HELPERS
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICES
import productService from '../../../services/products';


export default ({
	getProductsCrypto: createAsyncThunk('productsCrypto/get',
		async (params, thunkAPI) => await storeAddErrorNotification(
			() => productService.getProductsCrypto(), thunkAPI, false)),
})