// core
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

// async actions
import asyncActions from "./asyncAction";
import {IAdminSlice, IAdminCredentials, IAdminName} from "./index.D";


const initialState = {
	adminAuthed: null,
	isLoading:   true,
	error:       '',
} as IAdminSlice;

// initial reducer
const adminSlice = createSlice({
	name:     'admin',
	initialState,
	reducers: {
		setAdmin: (state: IAdminSlice, action: PayloadAction<IAdminCredentials>) => {
			state.adminAuthed = action.payload;

			return state;
		},
		clearAdmin: (state: IAdminSlice) => {
			state.adminAuthed = null;

			return state;
		},
		updateName: (state: IAdminSlice, action: PayloadAction<IAdminName>) => {
			if (state?.adminAuthed?.firstName) state.adminAuthed.firstName = action.payload.firstName;
			if (state?.adminAuthed?.lastName) state.adminAuthed.lastName = action.payload.lastName;

			return state;
		},
	},
	extraReducers: {
		[asyncActions.getMe.fulfilled.type]: (state: IAdminSlice, action: PayloadAction<IAdminCredentials>) => {
			state.adminAuthed = action.payload;
			state.isLoading = false;
		},
		[asyncActions.getMe.rejected.type]: (state: IAdminSlice) => {
			state.adminAuthed = null;
			state.isLoading = false;
		},
	}
});

export default ({
	...adminSlice,
	asyncActions
})
