import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import toRem from "../../../app/helpers/toRem";


export default makeStyles((theme: Theme) => createStyles({
	btnBlock: {
		flex: 1,
	},
	modal: {
		minWidth: toRem(350),
	}
}));