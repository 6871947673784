import {lazy} from "@loadable/component";
import {IRoutes} from "../routes.D";


export default ([
	{
		path:        '/forecast',
		title:       'Forecasting tool',
		component:   lazy(() => import('../../../components/views/Forecast')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['forecasting_tool_read'],
		routes:      [],
	}
]) as IRoutes[];