import { createAsyncThunk } from "@reduxjs/toolkit";
import { storeAddErrorNotification } from "../../../helpers/errors";
import spVerificationsService from "../../../services/spVerifications";


export default {
  getSpVerification: createAsyncThunk(
    "customers/getVerification",
    async (params: string, thunkAPI) =>
      await storeAddErrorNotification(
        () => spVerificationsService.getSpVerification(params),
        thunkAPI,
        false
      )
  ),
  getSpVerificationBusiness: createAsyncThunk(
    "customers/getVerificationBusiness",
    async (params: string, thunkAPI) =>
      await storeAddErrorNotification(
        () => spVerificationsService.getSpVerificationBusiness(params),
        thunkAPI,
        false
      )
  ),
};
