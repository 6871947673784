import {createSlice} from '@reduxjs/toolkit';
// TYPES
import {IMarginSlice} from "./index.D"
// ACTIONS
import asyncActions from './asyncActions';


const initialState = {
	globalMargins: [],
	baseMargin:    null,
	currencyPairs: {
		items: [],
		info:  {
			current_page:   1,
			next_page:      null,
			order:          null,
			order_asc_desc: "asc",
			per_page:       10,
			previous_page:  null,
			total_entries:  0,
			total_pages:    1,
		}
	},
	isLoading: false,
} as IMarginSlice

const marginSlice = createSlice({
	name:          'margins',
	initialState,
	reducers:      {},
	extraReducers: {
		[asyncActions.getMarginGlobal.fulfilled.type]: (state: IMarginSlice, action) => {
			state.globalMargins = action.payload;
			state.isLoading = false;
		},
		[asyncActions.getCurrencyPairs.fulfilled.type]: (state: IMarginSlice, action) => {
			state.currencyPairs = action.payload;
			state.isLoading = false;
		},
		[asyncActions.getMarginBase.fulfilled.type]: (state: IMarginSlice, action) => {
			state.baseMargin = action.payload;
			state.isLoading = false;
		},
		[asyncActions.createBaseMargin.fulfilled.type]: (state: IMarginSlice, action) => {
			state.baseMargin = action.payload;
			state.isLoading = false;
		},
		[asyncActions.updateBaseMargin.fulfilled.type]: (state: IMarginSlice, action) => {
			state.baseMargin = action.payload;
			state.isLoading = false;
		},
		[asyncActions.updateMarginsGlobal.fulfilled.type]: (state: IMarginSlice, action) => {
			state.globalMargins = action.payload;
			state.isLoading = false;
		},
		[asyncActions.updateMarginsGlobal.pending.type]: (state: IMarginSlice) => {
			state.isLoading = true;
		},
		[asyncActions.updateMarginsGlobal.rejected.type]: (state: IMarginSlice) => {
			state.isLoading = false;
		}
	},
});

export default ({
	...marginSlice,
	asyncActions
});