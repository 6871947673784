import { createSlice } from "@reduxjs/toolkit";
import { expiredAuth } from "../../../helpers/login";
import asyncActions from "./asyncActions";
import {
  removeRefreshToken,
  setRefreshToken,
  setSessionTime,
  setAccessToken,
  setTempToken,
  removeTempSession,
  setIdleTimeout,
  removeActivityData
} from "../../../helpers/localStorage";
import { ILogedIn } from "./index.D";


const initialState = {
  isLoggedIn:  !expiredAuth(),
  authDetails: {
    phone:         '',
    twoFactorAuth: [],
    factor:        0,
    qr:            '',
    token:         '',
  },
  lastActivityTime: +(localStorage.getItem('lastActivityTime') ?? 0) || Date.now(),
  inactive:         localStorage.getItem('inactive') === 'true',
  loading:          false,
  showSuccess:      false,
} as ILogedIn;

const authSlice = createSlice({
  name:     'auth',
  initialState,
  reducers: {
    login: (state: ILogedIn) => {
      if (!state.isLoggedIn) state.isLoggedIn = true;
    },
    unLog(state: ILogedIn) {
      if (state.isLoggedIn) state.isLoggedIn = false;
      state.inactive = false;
    },
    logoutTemp: (state) => {
      state.isLoggedIn = false;
      removeTempSession();
    },
    setLastActivityTime: (state, { payload }) => {
      state.lastActivityTime = payload;
      localStorage.setItem('lastActivityTime', payload);
    },
    setInactive: (state, { payload }) => {
      state.inactive = payload;
      localStorage.setItem('inactive', payload);
    },
  },
  extraReducers: {
    [asyncActions.login.fulfilled.type]: (state, action) => {
      setSessionTime(action.payload.sessionTime);
      setRefreshToken(action.payload.refreshToken);
      setAccessToken(action.payload.accessToken);
      setIdleTimeout(action.payload.idleTimeout)
      state.isLoggedIn = true;
    },
    // LOGIN 2FA (CREDENTIALS)
    [asyncActions.login2fa.pending.type]: (state) => {
      state.loading = true;
    },
    [asyncActions.login2fa.rejected.type]: (state) => {
      state.loading = false;
    },
    [asyncActions.login2fa.fulfilled.type]: (state, action) => {
      state.authDetails = action.payload;
      setTempToken(action.payload.tempToken);
      state.loading = false;
    },
    // CHECK 2FA CODE
    [asyncActions.confirmLogin.pending.type]: (state) => {
      state.loading = true;
    },
    [asyncActions.confirmLogin.rejected.type]: (state) => {
      state.loading = false;
    },
    [asyncActions.confirmLogin.fulfilled.type]: (state, action) => {
      setSessionTime(action.payload.sessionTime);
      setRefreshToken(action.payload.refreshToken);
      setAccessToken(action.payload.accessToken);
      setIdleTimeout(action.payload.idleTimeout);
      state.authDetails.factor = action.payload.factor;
      state.authDetails.twoFactorAuth = action.payload.twoFactorAuth;
      state.loading = false;
      state.showSuccess = true;
    },
    [asyncActions.confirmLoginWithLoggedIn.pending.type]: (state) => {
      state.loading = true;
    },
    [asyncActions.confirmLoginWithLoggedIn.rejected.type]: (state) => {
      state.loading = false;
    },
    [asyncActions.confirmLoginWithLoggedIn.fulfilled.type]: (state, action) => {
      setSessionTime(action.payload.sessionTime);
      setRefreshToken(action.payload.refreshToken);
      setAccessToken(action.payload.accessToken);
      setIdleTimeout(action.payload.idleTimeout);
      removeTempSession();
      state.authDetails.factor = action.payload.factor;
      state.authDetails.twoFactorAuth = action.payload.twoFactorAuth;
      state.isLoggedIn = true;
      state.loading = false;
      state.showSuccess = true;
    },
    [asyncActions.changePrimary2faMethod.pending.type]: (state) => {
      state.loading = true;
    },
    [asyncActions.changePrimary2faMethod.rejected.type]: (state) => {
      state.loading = false;
    },
    [asyncActions.changePrimary2faMethod.fulfilled.type]: (state, action) => {
      state.authDetails.twoFactorAuth = action.payload.twoFactorAuth;
      state.loading = false;
    },
    [asyncActions.checkCode.pending.type]: (state) => {
      state.loading = true;
    },
    [asyncActions.checkCode.rejected.type]: (state) => {
      state.loading = false;
    },
    [asyncActions.checkCode.fulfilled.type]: (state, { payload }) => {
      state.authDetails.twoFactorAuth = payload.twoFactorAuth;
      state.loading = false;
    },
    // SET 2FA METHOD
    [asyncActions.set2faMethod.pending.type]: (state) => {
      state.loading = true;
    },
    [asyncActions.set2faMethod.rejected.type]: (state) => {
      state.loading = false;
    },
    [asyncActions.set2faMethod.fulfilled.type]: (state, action) => {
      state.authDetails = action.payload;
      state.loading = false;
    },
    // RESEND CODE
    [asyncActions.resendCode.pending.type]: (state) => {
      state.loading = true;
    },
    [asyncActions.resendCode.rejected.type]: (state) => {
      state.loading = false;
    },
    [asyncActions.resendCode.fulfilled.type]: (state) => {
      state.loading = false;
    },
    // LOGOUT
    [asyncActions.logout.pending.type]: (state) => {
      state.loading = true;
    },
    [asyncActions.logout.rejected.type]: (state) => {
      state.loading = false;
    },
    [asyncActions.logout.fulfilled.type]: (state) => {
      removeRefreshToken();
      removeTempSession();
      removeActivityData();
      state.isLoggedIn = false;
      state.loading = false;
    },
    // CHANGE 2FA METHOD
    [asyncActions.change2faMethod.pending.type]: (state) => {
      state.loading = true;
    },
    [asyncActions.change2faMethod.rejected.type]: (state) => {
      state.loading = false;
    },
    [asyncActions.change2faMethod.fulfilled.type]: (state, action) => {
      state.authDetails = action.payload;
      state.authDetails.factor = action.payload.factor === 1 ? 2 : 1;
      state.loading = false;
    },
    // GET AUTH DETAILS
    [asyncActions.getAuthDetails.pending.type]: (state) => {
      state.loading = true;
    },
    [asyncActions.getAuthDetails.rejected.type]: (state) => {
      state.loading = false;
    },
    [asyncActions.getAuthDetails.fulfilled.type]: (state, action) => {
      state.authDetails = action.payload;
      setTempToken(action.payload.tempToken);
      state.loading = false;
    },
    // SET PHONE NUMBER
    [asyncActions.setPhone.pending.type]: (state) => {
      state.loading = true;
    },
    [asyncActions.setPhone.fulfilled.type]: (state, action) => {
      state.loading = false;
      state.authDetails.phone = action.payload.phone;
    },
    [asyncActions.setPhone.rejected.type]: (state) => {
      state.loading = false;
    },
    [asyncActions.zendeskSSOLogin.fulfilled.type]: (state, { payload }) => {
      setTempToken(payload.tempToken);
      state.authDetails = payload;
    },
    [asyncActions.zendeskSSOLoginVerify.fulfilled.type]: () => {
      localStorage.removeItem('tempToken');
    },
  }
});

export default ({
  ...authSlice,
  asyncActions
})