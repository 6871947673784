// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// HELPERS
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICES
import pooledAccountsService from '../../../services/pooledAccounts';


export default ({
	getPooledAccounts: createAsyncThunk('get/pooledAccounts',
		async (customerID: string, thunkAPI) => await storeAddErrorNotification(
			() => pooledAccountsService.getPooledAccounts(customerID), thunkAPI, false)),
});
