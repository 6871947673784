// CORE
import {createSlice} from '@reduxjs/toolkit';
// ASYNC ACTIONS
import asyncActions from './asyncActions';
// TYPES
import {defaultPagination} from '../search/pagination.D';
import {IWaitListSlice} from "./types";


const initialState = {
	loading: false,
	records: {
		items: [],
		info:  defaultPagination,
	}
} as IWaitListSlice;


const waitListSlice = createSlice({
	name:     'waitList',
	initialState,
	reducers: {
		clearState: (state) => {
			state.loading = false;
			state.records.info = defaultPagination;
			state.records.items = [];
		}
	},
	extraReducers: {
		[asyncActions.getWaitList.pending.type]: (state) => {
			state.loading = true;
		},
		[asyncActions.getWaitList.rejected.type]: (state) => {
			state.loading = false;
		},
		[asyncActions.getWaitList.fulfilled.type]: (state, action) => {
			state.loading = false;
			state.records = action.payload;
		},
	}
});

export default ({
	...waitListSlice,
	asyncActions,
});