import {createAsyncThunk} from '@reduxjs/toolkit';
import {storeAddErrorNotification} from '../../../helpers/errors';
import servicesCustomers from '../../../services/customers';
import {IEditCustomer, IEditCustomerAddress} from './index.D';
import {ICustomer} from "../customer/index.D";


export default ({
  getCustomers: createAsyncThunk('customers/get',
    async (params: { [key: string ]: any } | undefined, thunkAPI) => await storeAddErrorNotification(
      () => servicesCustomers.get(params), thunkAPI, false)),
  getCustomerCards: createAsyncThunk('customers/getCustomerCards',
    async (params: { [key: string ]: any } | undefined, thunkAPI) => await storeAddErrorNotification(
      () => servicesCustomers.getCustomerCards(params), thunkAPI, false)),
  deleteCustomer: createAsyncThunk('customers/deleteCustomer',
      async (id: string, thunkAPI) => await storeAddErrorNotification(
          () => servicesCustomers.deleteCustomer(id), thunkAPI, false)),
  deleteManyCustomers: createAsyncThunk('customers/deleteManyCustomers',
      async (params: ICustomer['_id'][], thunkAPI) => await storeAddErrorNotification(
          () => servicesCustomers.deleteManyCustomers(params), thunkAPI, false)),
  updateCustomer: createAsyncThunk('customers/updateCustomer',
      async (params: IEditCustomer, thunkAPI) => await storeAddErrorNotification(
          () => servicesCustomers.updateCustomer(params), thunkAPI, false)),
  updateCustomerRisk: createAsyncThunk('customers/updateCustomerRisk',
      async (params: IEditCustomer, thunkAPI) => await storeAddErrorNotification(
          () => servicesCustomers.updateCustomerRisk(params), thunkAPI, false)),
  updateCustomerAddress: createAsyncThunk('customers/updateCustomerAddress',
    async (params: IEditCustomerAddress, thunkAPI) => await storeAddErrorNotification(
      () => servicesCustomers.updateCustomerAddress(params), thunkAPI, false)),
});
