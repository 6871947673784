import notifierModule from '../../store/reducers/notifier';
import {AppDispatch} from "../../store";


interface  IOptions {
	key?:              string | number,
	variant:          string,
	autoHideDuration?: number,
}

export interface ICreateDefaultNotify {
	message: string;
	options: IOptions,
}

const {
	enqueueNotification,
	closeNotification,
} = notifierModule.actions;

const createDefaultNotify = (message = 'Notify', options = {}): ICreateDefaultNotify => ({
	message,
	options: {
		key:              (new Date().getTime() + Math.random()),
		variant:          'info',
		autoHideDuration: 5000,
		...options,
	},
});

export const createNotification = (dispatch: AppDispatch, message: string, options: IOptions) => {
	dispatch(
		enqueueNotification(
			createDefaultNotify(message, options),
		),
	);
};

export const removeNotification = (dispatch: AppDispatch, key: string | number) => {
	dispatch(closeNotification(key));
};

export const showSuccessNotification = (dispatch: AppDispatch, message: string) => {
	createNotification(dispatch, message, {
		variant: 'success',
	})
}