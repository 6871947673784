// HELPERS
import getBrand from "../../helpers/getBrand";
// TYPES
import {ITransactionData} from "../../store/reducers/limits/index.D";
// AXIOS
import {apiClient} from "../../clients/api.client";


const {get, put, post} = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default ({
	create:    (data: ITransactionData) => post(`${URL()}/limits`, data),
	update:    ({id, data}: { id: string, data: ITransactionData }) => put(`${URL()}/limits/${id}`, data),
	getLimits: () => get(`${URL()}/limits`),
});
