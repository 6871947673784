import {createAsyncThunk} from '@reduxjs/toolkit';
// HELPERS
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICES
import rulesService from '../../../services/manageCompliance';
// TYPES
import {IUpdateParams} from "./index.D"
import {IUpdatedManageCompliance} from "../../../../components/modules/ManageCompliance/GeneralDetails/index.D"


export default ({
	getRuleById: createAsyncThunk('ruleById/get',
		async (id: string, thunkAPI) => await storeAddErrorNotification(
			() => rulesService.getById(id), thunkAPI, false)),
	updateRule: createAsyncThunk('updateRule/put',
		async (data: IUpdateParams, thunkAPI) => await storeAddErrorNotification(
			() => rulesService.update(data.id, data.rule), thunkAPI, false)),
	deleteRule: createAsyncThunk('deleteRule/del',
		async (id: string, thunkAPI) => await storeAddErrorNotification(
			() => rulesService.delete(id), thunkAPI, false)),
	createRule: createAsyncThunk('createRule/post',
		async (data: IUpdatedManageCompliance, thunkAPI) => await storeAddErrorNotification(
			() => rulesService.create(data), thunkAPI, false)),
});
