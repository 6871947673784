// CORE
import { createAsyncThunk } from "@reduxjs/toolkit";
// HELPERS
import { storeAddErrorNotification } from "../../../helpers/errors";
// SERVICE
import riskToleranceSettingsService from "../../../services/riskToleranceSettings";
// TYPES
import { IUpdateRiskTolerance } from "./index.D";


export default {
  getRiskToleranceSettings: createAsyncThunk(
    "riskTolerance/get",
    async (params, thunkAPI) =>
      await storeAddErrorNotification(
        () => riskToleranceSettingsService.getRiskToleranceSettings(),
        thunkAPI
      )
  ),
  updateRiskToleranceSettings: createAsyncThunk(
    "riskTolerance/update",
    async (data: IUpdateRiskTolerance, thunkAPI) =>
      await storeAddErrorNotification(
        () => riskToleranceSettingsService.updateRiskToleranceSettings(data),
        thunkAPI
      )
  ),
};
