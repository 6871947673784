import {lazy} from "@loadable/component";
import {IRoutes} from "../routes.D";


export default ([
	{
		path:        '/margins-fees',
		title:       'Margins & Fees',
		component:   lazy(() => import('../../../components/views/MarginsFees')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['margins_fees_read'],
		routes:      []
	},
	{
		path:        '/crypto-margins-fees',
		title:       'Crypto Margins & Fees',
		component:   lazy(() => import('../../../components/views/CryptoMarginsFees')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['margins_fees_read'],
		routes:      []
	},
	{
		path:        '/base-margin-fee',
		title:       'Base Margin & Fee',
		component:   lazy(() => import('../../../components/views/BaseMarginFee')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['margins_fees_read'],
		routes:      []
	},
	// {
	// 	path:        '/organizations-fees',
	// 	title:       'Organizations Fees',
	// 	component:   lazy(() => import('../../../components/views/OrganizationsFees')),
	// 	layout:      lazy(() => import('../../../components/layouts/MainLayout')),
	// 	exact:       true,
	// 	private:     true,
	// 	permissions: ['margins_fees_read'],
	// 	routes:      []
	// },
	{
		path:        '/transactions-margins-fees',
		title:       'Transactions Margins & Fees',
		component:   lazy(() => import('../../../components/views/TransactionsMarginsFees')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['margins_fees_read'],
		routes:      []
	},
] as IRoutes[]);