import {lazy} from "@loadable/component";
import {IRoutes} from "../routes.D";


export default ([
	{
		path:        '/waiting-list',
		title:       'Waiting list',
		component:   lazy(() => import('../../../components/views/WaitList')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['wait_list_read'],
		routes:      []
	}
] as IRoutes[]);