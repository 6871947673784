import {createSlice} from '@reduxjs/toolkit';
// TYPES
import {IPooledAccountsSlice} from "./index.D";
// ACTIONS
import asyncActions from './asyncActions';


const initialState = {
  items:     [],
	isLoading: false,
} as IPooledAccountsSlice;

const pooledAccountsSlice = createSlice({
	name:     'pooledAccounts',
	initialState,
	reducers: {
		clearPooledAccounts(state: IPooledAccountsSlice) {
			state.items = [];
      
			return state;
		}
	},
	extraReducers: {
    [asyncActions.getPooledAccounts.pending.type]: (state: IPooledAccountsSlice) => {
			state.isLoading = true;
		},
    [asyncActions.getPooledAccounts.rejected.type]: (state: IPooledAccountsSlice) => {
			state.isLoading = false;
		},
		[asyncActions.getPooledAccounts.fulfilled.type]: (state: IPooledAccountsSlice, action) => {
			state.items = action.payload;
			state.isLoading = false;
		},
	},
});

export default ({
	...pooledAccountsSlice,
	asyncActions
});