import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// TYPES
import {IDigitalAssetSlice} from "./index.D";
// ACTIONS
import asyncActions from './asyncActions';


const initialState = {
	items:         [],
	isLoading:     false,
	manageLoading: false,
} as IDigitalAssetSlice;

const digitalAssetsSlice = createSlice({
	name:          'digitalAssets',
	initialState,
	reducers:      {},
	extraReducers: {
		[asyncActions.getDigitalAssets.fulfilled.type]: (state: IDigitalAssetSlice, action) => {
			state.items = action.payload;
		},

		[asyncActions.setDigitalAssetAvailable.pending.type]: (state: IDigitalAssetSlice) => {
			state.manageLoading = true;
		},
		[asyncActions.setDigitalAssetAvailable.rejected.type]: (state: IDigitalAssetSlice) => {
			state.manageLoading = false;
		},
		[asyncActions.setDigitalAssetAvailable.fulfilled.type]: (state: IDigitalAssetSlice, action) => {
			const codes = action.payload.codes;
			state.items = state.items.map((a) => codes?.includes(a.code) ? {...a, available: true} : a)
			state.manageLoading = false;
		},

		[asyncActions.setDigitalAssetUnavailable.pending.type]: (state: IDigitalAssetSlice) => {
			state.manageLoading = true;
		},
		[asyncActions.setDigitalAssetUnavailable.rejected.type]: (state: IDigitalAssetSlice) => {
			state.manageLoading = false;
		},
		[asyncActions.setDigitalAssetUnavailable.fulfilled.type]: (state: IDigitalAssetSlice, action) => {
			const codes = action.payload.codes;
			state.items = state.items.map((a) => codes?.includes(a.code) ? {...a, available: false} : a)
			state.manageLoading = false;
		},

		[asyncActions.editDigitalAssets.pending.type]: (state: IDigitalAssetSlice) => {
			state.manageLoading = true;
		},
		[asyncActions.editDigitalAssets.rejected.type]: (state: IDigitalAssetSlice) => {
			state.manageLoading = false;
		},
		[asyncActions.editDigitalAssets.fulfilled.type]: (state: IDigitalAssetSlice, action) => {
			state.manageLoading = false;
			const {codes, available, riskLevel} = action.payload;
			state.items = state.items.map((a) => codes.includes(a.code) ? {...a, available, riskLevel} : a);
		},
	}
});

export default ({
	...digitalAssetsSlice,
	asyncActions,
});