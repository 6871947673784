// TYPES
import {IRoutes} from './routes.D'
// ROUTES
import adminRoutes from './subRoutes/admin.routes'
// import apiKeyRoutes from './subRoutes/apiKey.routes'
import beneficiaryRoutes from './subRoutes/beneficiary.routes'
import businessRoutes from './subRoutes/business.routes'
import businessContactRoutes from './subRoutes/businessContact.routes'
import cardRoutes from './subRoutes/card.routes'
import customerRoutes from './subRoutes/customer.routes'
import dashboardRoutes from './subRoutes/dashboard.routes'
import documentRoutes from './subRoutes/document.routes'
import manageComplianceRoutes from './subRoutes/manageCompliance.routes'
import manageDocumentRoutes from './subRoutes/manageDocument.routes'
import manageRiskRoutes from './subRoutes/manageRisk.routes'
import marginFeeRoutes from './subRoutes/marginFees.routes'
import reportRoutes from './subRoutes/report.routes'
import riskAlertRoutes from './subRoutes/riskAlert.routes'
import transferRoutes from './subRoutes/transfer.routes'
import limitsRoutes from './subRoutes/dailyLimits.routes'
import usageRoutes from "./subRoutes/usage.routes";
import forecastRoutes from "./subRoutes/forecast.route";
import batchedTransfersRoutes from './subRoutes/transferManagement.routes'
import bankDetailsRoutes from "./subRoutes/bankDetails.routes";
import settingsRoutes from "./subRoutes/settings.routes";
import jurisdictionsRoutes from "./subRoutes/jurisdictions.routes";
import waitListRoutes from "./subRoutes/waitList.routes";
import digitalAssetsRoutes from "./subRoutes/digitalAssets.routes";
import businessActivityRoutes from "./subRoutes/businessActivity.routes";
import pricePlansRoutes from "./subRoutes/pricePlan.routes";
import registrationRequestRoutes from "./subRoutes/registrationRequest.routes";
import transactionsCosts from "./subRoutes/transactionsCosts.routes";
import questionnaireRoutes from "./subRoutes/questionnaire.route";
import organizationsRoutes from "./subRoutes/organizations.routes";


export default ([
	...adminRoutes,
	// ...apiKeyRoutes,
	...beneficiaryRoutes,
	...businessRoutes,
	...businessContactRoutes,
	...cardRoutes,
	...customerRoutes,
	...dashboardRoutes,
	...documentRoutes,
	// ...logRoutes,
	...manageComplianceRoutes,
	...manageDocumentRoutes,
	...manageRiskRoutes,
	...marginFeeRoutes,
	...reportRoutes,
	...riskAlertRoutes,
	...transferRoutes,
	...limitsRoutes,
	...usageRoutes,
	...forecastRoutes,
	...batchedTransfersRoutes,
	...bankDetailsRoutes,
	...settingsRoutes,
	...jurisdictionsRoutes,
	...waitListRoutes,
	...digitalAssetsRoutes,
	...businessActivityRoutes,
	...pricePlansRoutes,
	...registrationRequestRoutes,
	...transactionsCosts,
	...questionnaireRoutes,
	...organizationsRoutes,
] as IRoutes[]);
