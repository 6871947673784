import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import asyncActions from './asyncActions';
import {ICustomer, ICustomersSlice} from "./index.D";
import { defaultPagination, IPagination } from '../search/pagination.D';
import { ICard } from '../cards/index.D';


const initialState = {
  customers: {
    items: [],
    info:  defaultPagination
  },
  cards: {
    items: [],
    info:  defaultPagination
  },
  isLoading: false,
  error:     '',
  selected:  []
} as ICustomersSlice

const customersSlice = createSlice({
  name:     'customers',
  initialState,
  reducers: {
    setSelected: (state: ICustomersSlice, payload: PayloadAction<Array<ICustomer['_id']> | []>) => {
      state.selected = payload.payload
    },
    clearCards(state: ICustomersSlice) {
      state.cards = {
        items: [],
        info:  {
          current_page:   1,
          next_page:      null,
          order:          null,
          order_asc_desc: "asc",
          per_page:       10,
          previous_page:  null,
          total_entries:  0,
          total_pages:    1,
        }
      }

      return state;
    }
  },
  extraReducers: {
    [asyncActions.getCustomers.fulfilled.type]: (state: ICustomersSlice, action) => {
      state.customers = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.getCustomers.pending.type]: (state: ICustomersSlice) => {
      state.isLoading = true;
      state.customers = { items: [], info: defaultPagination };
    },
    [asyncActions.getCustomers.rejected.type]: (state: ICustomersSlice, action: PayloadAction<ICustomersSlice>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [asyncActions.getCustomerCards.fulfilled.type]: 
      (state: ICustomersSlice, action: PayloadAction<IPagination<ICard>>) => 
    {
      state.cards = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.updateCustomer.fulfilled.type]: (
        state: ICustomersSlice,
        { payload }: PayloadAction<ICustomer>
    ) => {
      const index = state.customers.items.findIndex(({_id}) => _id === payload._id);
      const customer = state.customers.items[index];
      const newCustomers = [...state.customers.items];
      newCustomers[index] = {...customer, ...payload};
      state.customers.items = newCustomers;
    },
  },
});

export default ({
  ...customersSlice,
  asyncActions,
});