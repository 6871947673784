import {createAsyncThunk} from '@reduxjs/toolkit';
import {storeAddErrorNotification} from '../../../helpers/errors';
import reportsService from '../../../../app/services/reports'
import {IReportQuerys, ICreateMonthlyFee, IUpdateMonthlyFee, IConversionRequest} from './index.D'


export default ({
	findLatestMonthlyFees: createAsyncThunk('find/monthlyFees',
		async (params, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.findLatestMonthlyFees(), thunkAPI, false)),
	findMonthlyFees: createAsyncThunk('find/monthlyFees',
		async (params: IReportQuerys, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.findMonthlyFees(params), thunkAPI, false)),
	getMonthlyFeesReport: createAsyncThunk('find/monthlyFees',
		async (params: IReportQuerys, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.getMonthlyFeesReport(params), thunkAPI, false)),	
	createMonthlyFees: createAsyncThunk('create/monthlyFees',
		async (fee: ICreateMonthlyFee, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.createMonthlyFees(fee), thunkAPI, false)),
	updateMonthlyFees: createAsyncThunk('update/monthlyFees',
		async ({id, data}: IUpdateMonthlyFee, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.updateMonthlyFees(id, data), thunkAPI, false)),
	findFees: createAsyncThunk('find/fees',
		async (params: IReportQuerys, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.findFees(params), thunkAPI, false)),
	findMargins: createAsyncThunk('find/margins',
		async (params: IReportQuerys, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.findMargins(params), thunkAPI, false)),
	findRevenueShare: createAsyncThunk('find/revenueShare',
		async (params: IReportQuerys, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.findRevenueShare(params), thunkAPI, false)),
	findPayments: createAsyncThunk('find/payments',
		async (params: IReportQuerys, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.findPayments(params), thunkAPI, false)),
	findConversions: createAsyncThunk('find/conversions',
		async (params: IReportQuerys, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.findConversions(params), thunkAPI, false)),
	findConversionsSell: createAsyncThunk('find/conversionsSell',
		async (params: IReportQuerys, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.findConversions({...params, sell: 'true'}), thunkAPI, false)),
	findFunds: createAsyncThunk('find/funding',
		async (params: IReportQuerys, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.findFunds(params), thunkAPI, false)),
	findCardLoads: createAsyncThunk('find/cardLoads',
		async (params: IReportQuerys, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.findCardLoads(params), thunkAPI, false)),
	getForecastDefaults: createAsyncThunk('find/forecast',
		async (params, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.getForecastDefaults(), thunkAPI, false)),

	getCryptoReconciliationReport: createAsyncThunk('reports/cryptoReconciliation',
		async (data: {currencies: string[], dateFrom: Date, dateTo: Date}, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.getCryptoReconciliationReport(data), thunkAPI, false)),
	getCryptoToFiatReport: createAsyncThunk('reports/cryptoToFiat',
		async (params: IConversionRequest, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.getCryptoToFiatReport(params), thunkAPI, false)),
	getFiatToCryptoReport: createAsyncThunk('reports/fiatToCrypto',
		async (params: IConversionRequest, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.getFiatToCryptoReport(params), thunkAPI, false)),
	getMarginReport: createAsyncThunk('reports/margin',
		async (params: IConversionRequest, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.getMarginReport(params), thunkAPI, false)),
	getFeesReport: createAsyncThunk('reports/fees',
		async (params: IConversionRequest, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.getFeesReport(params), thunkAPI, false)),
});
