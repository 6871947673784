import React, { FC, useEffect } from 'react';
// Plugin
import { useAppDispatch, useAppSelector } from './app/hooks/redux.hook';
import { createNotification } from './app/helpers/notification';
import { removeActivityData, removeRefreshToken } from './app/helpers/localStorage';


interface Props {
  children?: React.ReactNode;
}

const onConnect: FC<Props> = ({ children }) => {
  const { isLoggedIn = false } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isLoggedIn) {

      const logoutReason = localStorage.getItem('logoutReason');

      if (logoutReason === 'sessionTimeout') {
        createNotification(dispatch, "Your session has timed out. Please log in again.", { variant: 'error' });
        localStorage.removeItem('logoutReason');
      }
      removeActivityData();
      removeRefreshToken();
    }
  }, [isLoggedIn]);

  return (
    <>
      {children}
    </>
  );
};

export default onConnect;