import {createAsyncThunk} from '@reduxjs/toolkit';
// HELPERS
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICES
import marginService from '../../../services/margins';
// TYPES
import {
	ICreateGlobalMargin,
	IUpdateGlobalMargin,
	IQueriesMargins,
	ICreateCurrencyPair,
	IUpdateCurrencyPair,
	IUpdateBaseMargin,
	IUpdateGlobalMargins
} from './index.D'


export default ({
	getMarginGlobal: createAsyncThunk('globalMargin/get',
		async (params, thunkAPI) => await storeAddErrorNotification(
			() => marginService.getGlobalMargin(), thunkAPI, false)),
	createMarginGlobal: createAsyncThunk('globalMargin/create',
		async (data: ICreateGlobalMargin, thunkAPI) => await storeAddErrorNotification(
			() => marginService.createGlobalMargin(data), thunkAPI, false)),
	updateMarginGlobal: createAsyncThunk('globalMargin/update',
		async (data: IUpdateGlobalMargin, thunkAPI) => await storeAddErrorNotification(
			() => marginService.updateGlobalMargin(data), thunkAPI, false)),
	updateMarginsGlobal: createAsyncThunk('globalMargins/updateAll',
		async (data: IUpdateGlobalMargins, thunkAPI) => await storeAddErrorNotification(
			() => marginService.updateGlobalMargins(data), thunkAPI, false)),
	getCurrencyPairs: createAsyncThunk('currencyPairs/get',
		async (params: IQueriesMargins, thunkAPI) => await storeAddErrorNotification(
			() => marginService.getCurrencyPairs(params), thunkAPI, false)),
	delCurrencyPairs: createAsyncThunk('currencyPair/del',
		async (id: string, thunkAPI) => await storeAddErrorNotification(
			() => marginService.deleteCurrencyPairs(id), thunkAPI, false)),
	createCurrencyPair: createAsyncThunk('currencyPair/create',
		async (data: ICreateCurrencyPair, thunkAPI) => await storeAddErrorNotification(
			() => marginService.createCurrencyPair(data), thunkAPI, false)),
	updateCurrencyPair: createAsyncThunk('currencyPair/update',
		async (data: IUpdateCurrencyPair, thunkAPI) => await storeAddErrorNotification(
			() => marginService.updateCurrencyPair(data.id, data.data), thunkAPI, false)),
	getMarginBase: createAsyncThunk('baseMargin/get',
		async (params, thunkAPI) => await storeAddErrorNotification(
			() => marginService.getBaseMargin(), thunkAPI, false)),
	createBaseMargin: createAsyncThunk('baseMargin/create',
		async (data: ICreateGlobalMargin, thunkAPI) => await storeAddErrorNotification(
			() => marginService.createBaseMargin(data), thunkAPI, false)),
	updateBaseMargin: createAsyncThunk('baseMargin/update',
		async (data: IUpdateBaseMargin, thunkAPI) => await storeAddErrorNotification(
			() => marginService.updateBaseMargin(data.id, data.data), thunkAPI, false)),
});
