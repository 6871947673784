import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import asyncActions from './asyncActions';
import {IBeneficiary, IBeneficiariesSlice} from "./index.D";
import {defaultPagination} from "../search/pagination.D";


const initialState = {
	beneficiaries: {
		items: [],
		info:  defaultPagination
	},
	isLoading: false,
	error:     '',
	selected:  [],
} as IBeneficiariesSlice

const beneficiariesSlice = createSlice({
	name:     'beneficiaries',
	initialState,
	reducers: {
		clearBeneficiaries(state: IBeneficiariesSlice) {
			state.beneficiaries = {
				items: [],
				info:  {
					current_page:   1,
					next_page:      null,
					order:          null,
					order_asc_desc: "asc",
					per_page:       10,
					previous_page:  null,
					total_entries:  0,
					total_pages:    1,
				}
			}

			return state;
		}
	},
	extraReducers: {
		[asyncActions.getBeneficiaries.fulfilled.type]: (state, action) => {
			state.beneficiaries = action.payload;
			state.isLoading = false;
			state.error = '';
		},
		[asyncActions.getBeneficiariesByCustomer.fulfilled.type]: (state, action) => {
			state.beneficiaries = action.payload;
			state.isLoading = false;
			state.error = '';
		},
		[asyncActions.getBeneficiaries.pending.type]: (state) => {
			state.isLoading = true;
		},
		[asyncActions.getBeneficiaries.rejected.type]: (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		},
		[asyncActions.getBusinessBeneficiaries.fulfilled.type]: (state, action) => {
			state.beneficiaries = action.payload;
			state.isLoading = false;
			state.error = '';
		},
		[asyncActions.getBusinessBeneficiaries.pending.type]: (state) => {
			state.isLoading = true;
		},
		[asyncActions.getBusinessBeneficiaries.rejected.type]: (state, action) => {
			state.isLoading = false;
			state.error = action.payload;
		},
		// eslint-disable-next-line max-len
		[asyncActions.deleteBeneficiary.fulfilled.type]: (state: IBeneficiariesSlice, action: PayloadAction<IBeneficiary>) => {
			state.isLoading = false;
			// state.beneficiaries = state.beneficiaries.filter(({_id}) => _id !== action.payload._id);
		},
	},
});

export default ({
	...beneficiariesSlice,
	asyncActions,
});
