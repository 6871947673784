// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// HELPERS
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICE
import manualTransactionsService from "../../../services/manualTransactions";
// TYPES
import {ICreateDepositPayload} from "./index.D";


export default ({
	createDeposit: createAsyncThunk('manualTransactions/createDeposit',
		async (data: ICreateDepositPayload, thunkAPI) => await storeAddErrorNotification(
			() => manualTransactionsService.createDeposit(data), thunkAPI
		)),

	getWithdrawals: createAsyncThunk('manualTransactions/getWithdrawals',
		async (params: Record<string, string | number>, thunkAPI) => await storeAddErrorNotification(
			() => manualTransactionsService.getWithdrawals(params), thunkAPI
		)),
	getWithdrawalsMore: createAsyncThunk('manualTransactions/getWithdrawalsMore',
		async (params: Record<string, string | number>, thunkAPI) => await storeAddErrorNotification(
			() => manualTransactionsService.getWithdrawals(params), thunkAPI
		)),
	updateWithdrawal: createAsyncThunk('manualTransactions/updateWithdrawal',
		async (data: {id:string, status: number}, thunkAPI) => await storeAddErrorNotification(
			() => manualTransactionsService.updateWithdrawal(data), thunkAPI
		)),
	updateManyWithdrawal: createAsyncThunk('manualTransactions/updateWithdrawalMany',
		async (data: {ids: string[], status: number}, thunkAPI) => await storeAddErrorNotification(
			() => manualTransactionsService.updateManyWithdrawal(data), thunkAPI
		)),
	sendDepositBack: createAsyncThunk('manualTransactions/deposit/back',
		async(data: {transferId: string}, thunkAPI) => await storeAddErrorNotification(
			() => manualTransactionsService.sendDepositBack(data), thunkAPI
		))
});