import {createTheme, Theme} from "@mui/material";
import colors from './colors'
import toRem from "../helpers/toRem";
import {fonts} from "./font";


declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        check: true;
    }
}


export const theme: Theme = createTheme({
    typography: {
        fontFamily: [fonts[2].fontFamily, 'sans-serif'].join(', '),
        h1:         {
            fontSize:   toRem(24),
            fontWeight: 600,
            lineHeight: 1.3,
            color:      colors.text.black,
        },
        h4: {
            fontSize:   toRem(28),
            fontWeight: 600,
            lineHeight: 1.3,
            color:      colors.text.black,
        },
        h5: {
            fontSize:   toRem(20),
            fontWeight: 600,
            lineHeight: 1.3,
            color:      colors.text.black,
        },
        body2: {
            fontSize:   toRem(16),
            fontWeight: 400,
            lineHeight: 1.5,
            color:      colors.text.secondary,
        },
        subtitle2: {
            fontSize:   toRem(16),
            fontWeight: 500,
            lineHeight: 1.6,
            color:      colors.text.black,
        }
    },
    components: {
        MuiPaper: {
          styleOverrides: {
              root: {
                  '&::-webkit-scrollbar': {
                      width: `${toRem(2)}!important`,
                  },

                  '&::-webkit-scrollbar-thumb': {
                      borderRadius:    `${toRem(2)}!important`,
                      backgroundColor: `${colors.text.purple}!important`,
                  },
              }
          }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: 'none'
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    borderBottom: `${toRem(1)} solid rgba(224, 224, 224, 1)`
                }
            }
        },
        MuiButton: {
            variants: [{
                props: {variant: 'check'},
                style: {
                    border: `${toRem(1)} solid ${colors.bg.grey7}`,
                    color:  colors.text.grey,
                },
            },
                {
                    props: {variant: 'contained'},
                    style: {
                        background:    colors.bg.violet,
                        textTransform: 'capitalize'
                    },
                },
                {
                    props: {variant: 'contained', color: 'primary'},
                    style: {
                        '&.Mui-disabled': {
                            background: `${colors.bg.primaryDisabled}!important`,
                        }
                    },
                },
                {
                    props: {variant: 'contained', size: 'medium', color: 'primary'},
                    style: {
                        background: colors.bg.violet,
                        padding:    toRem(15),
                        fontSize:   toRem(14),
                        fontWeight: 500,
                        lineHeight: 1.45,
                    },
                },
                {
                    props: {variant: 'contained', color: 'error'},
                    style: {
                        background:    colors.bg.red,
                        textTransform: 'capitalize'
                    },
                },
                {
                    props: {variant: 'contained', color: 'success'},
                    style: {
                        background:    colors.bg.green,
                        textTransform: 'capitalize'
                    },
                },
                {
                    props: {variant: 'outlined'},
                    style: {
                        borderColor:   colors.bg.violet,
                        color:         colors.text.black,
                        borderWidth:   toRem(2),
                        textTransform: 'capitalize'

                    },
                },
                {
                    props: {variant: 'outlined', color: 'primary', size: 'medium'},
                    style: {
                        color:           colors.bg.violet,
                        backgroundColor: colors.bg.white,
                        borderColor:     colors.bg.violet,
                        borderWidth:     toRem(1),
                        padding:         toRem(14),
                        fontSize:        toRem(14),
                        fontWeight:      500,
                        lineHeight:      1.45,
                    },
                },
                {
                    props: {variant: 'text'},
                    style: {
                        textTransform: 'capitalize'
                    },
                },
                {
                    props: {variant: 'text', color: 'primary', size: 'medium'},
                    style: {
                        color:      colors.text.purple,
                        fontSize:   toRem(14),
                        fontWeight: 500,
                        padding:    0,
                        '&:hover':  {
                            backgroundColor: `transparent!important`,
                            textDecoration:  'underline',
                        },
                        '& .MuiTouchRipple-root': {
                            display: 'none!important'
                        },
                    },
                },
                {
                    props: {variant: 'text', color: 'primary', size: 'large'},
                    style: {
                        color:      colors.text.purple,
                        fontSize:   toRem(16),
                        fontWeight: 500,
                        padding:    0,
                        '&:hover':  {
                            backgroundColor: `transparent!important`,
                            textDecoration:  'underline',
                        },
                        '& .MuiTouchRipple-root': {
                            display: 'none!important'
                        },
                    },
                },
            ],
            styleOverrides: {
                root: {
                    borderRadius: toRem(8),
                },
                sizeSmall: {
                    // minWidth: 'unset',
                    height: toRem(36)
                    // padding:  `${toRem(6.8)} ${toRem(16)}`
                },
            }
        }
    }
});


