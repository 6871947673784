import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import asyncActions from './asyncActions';
import {IBeneficiarySlice, IBeneficiary, IBeneficiaryCC} from './index.D';


const initialState = {
    beneficiary:   {},
    beneficiaryCC: null,
    isLoading:     false,
    error:         ''
} as IBeneficiarySlice

const beneficiarySlice = createSlice({
    name:          'beneficiary',
    initialState,
    reducers:      {},
    extraReducers: {
        // eslint-disable-next-line max-len
        [asyncActions.getBeneficiary.fulfilled.type]: (state: IBeneficiarySlice, action: PayloadAction<IBeneficiary>) => {
            state.beneficiary = action.payload;
            state.isLoading = false;
            state.error = '';
        },
        [asyncActions.getBeneficiary.pending.type]: (state: IBeneficiarySlice) => {
            state.isLoading = true;
        },
        // eslint-disable-next-line max-len
        [asyncActions.getBeneficiary.rejected.type]: (state: IBeneficiarySlice, action: PayloadAction<IBeneficiary>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        // eslint-disable-next-line max-len
        [asyncActions.getBeneficiaryCC.fulfilled.type]: (state: IBeneficiarySlice, action: PayloadAction<IBeneficiaryCC>) => {
            state.beneficiaryCC = action.payload;
            state.isLoading = false;
            state.error = '';
        },
    },
});

export default ({
    ...beneficiarySlice,
    asyncActions,
});
