// CORE
import React, {ReactElement, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
import {lazy} from "@loadable/component";
import {QueryParamProvider} from 'use-query-params';
// CONSTANTS
import routes from "./routes";
// HOOKS
import useGetMe from "../hooks/me.hook";
import useGetHosts from "../hooks/hosts.hook";
import useMainRoutes from "../hooks/useMainRoutes.hook";
import useGetCurrentOrg from '../hooks/useGetCurrentOrg';
// HOC
import PrivateRoutes from "../hoc/PrivateRoutes";
import PublicRoutes from "../hoc/PublicRoutes";
import { useAppSelector } from '../hooks/redux.hook';


const Routes = ():ReactElement => {
	const mainRoutes = useMainRoutes()

	useGetMe()
	useGetHosts()
	useGetCurrentOrg()

	const isLoading = useAppSelector(({admin}) => admin?.isLoading);
	const isHostLoading = useAppSelector(state => state.organizations.isHostLoading);
	const isOrgLoading = useAppSelector(state => state.organizations.isLoading);


	return (
		<QueryParamProvider ReactRouterRoute={Route}>
			<Suspense fallback={null}>
				<Switch>
					{isLoading || isHostLoading || isOrgLoading
						? <Route component={lazy(() => import('../../components/views/Loading'))}/> : null}
					{routes.authRoutes.map((route, i) => <PublicRoutes key={i} {...route} />)}
					{mainRoutes.map((route, i) => <PrivateRoutes key={i} {...route} />)}
					<Route component={lazy(() => import('../../components/views/NotFound'))}/>
				</Switch>
			</Suspense>
		</QueryParamProvider>
	)
};

export default Routes;
