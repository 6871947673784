export const defaultColorSchema = {
    main:            '#8552F5',
    mainPressed:     '#6E45C8',
    mainDisabled:    '#E7DCFD',
    secondary:       '#5CE47A',
    secondaryOrange: '#FDAC4E',
    secondaryDark:   '#271E42',
    black:           '#0C022A',
    card:            '#420771',
    error:           '#FF4B55',
    text:            '#0C022A',
    textSecondary:   '#8D8D8D',
    textLight:       '#A498C7',
    inputDefault:    '#BFBFBF',
    link:            '#8552F5',
    white:           '#FFFFFF',
    bg:              '#F9F9F9',
    bgCard:          '#2C1141',
    tagPurple:       '#F6EFFE',
    tagRed:          '#FEEDED',
    tagOrange:       '#FFF6EE',
    border:          '#EFEBFA',
    buttonBorder:    '#E2E3E5',
    divider:         '#E7E9F0',
} as const;

export const iconsKeys = ['logoDarkBase64', 'logoLightBase64', 'saveIconBase64', 'faviconBase64'] as const;
