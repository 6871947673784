// API
import {apiClient} from "../../clients/api.client";
// HELPERS
import getBrand from "../../helpers/getBrand"
import objectToURL from "../../helpers/objectToURL";
// TYPES
import {
	IQueriesRules,
	IProofData,
	IUpdateProof,
	IUpdateAuthProof,
	ICreateVerificationProof,
	IParams
} from "../../store/reducers/proofs/index.D"


const {get, post, put, delete: del} = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default ({
	getProofs: (params: IQueriesRules): Promise<string> => get(
		`${URL()}/compliance?${objectToURL(params)}`),
	getProofById:       (id: string): Promise<string> => get(`${URL()}/compliance/${id}`),
	getAuthProofByType: (params: IQueriesRules): Promise<string> => get(
		`${URL()}/auth-compliance?${objectToURL(params)}`),
	createProof:             (data: IProofData): Promise<string> => post(`${URL()}/compliance`, data),
	updateProof:             ({id, data}: IUpdateProof): Promise<string> => put(`${URL()}/compliance/${id}`, data),
	deleteProof:             (id: string): Promise<string> => del(`${URL()}/compliance/${id}`),
	getVerificationProofs:   (params: IParams) => get(`${URL()}/verification-proofs?${objectToURL(params)}`),
	createVerificationProof: (data: ICreateVerificationProof): Promise<string> => post(
		`${URL()}/verification-proofs`, data),
	updateAuthProof: ({id, data}: IUpdateAuthProof): Promise<string> => put(
		`${URL()}/auth-compliance/${id}`, data),
	getUnloadedProof: (id: string): Promise<string> => get(
		`${URL()}/verifications/${id}/verification-proofs`),
	deleteProofRequest: (id: string): Promise<string> => del(
		`${URL()}/verification-proofs/${id}`),
});
