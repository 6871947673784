// API
import {apiClient} from "../../clients/api.client";
// HELPERS
import getBrand, {isJigzoBrand} from "../../helpers/getBrand"


const {get} = apiClient;
const URL = () => `organizations/${getBrand(isJigzoBrand())}`;

export default ({
	getProducts:       (): Promise<string> => get(`${URL()}/products`),
	getProductsCrypto: () => get(`${URL()}/products/crypto`),
});
