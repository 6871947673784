import {lazy} from "@loadable/component";
import {IRoutes} from "../routes.D";
import {orgTypes} from "../../store/reducers/organizations/index.D";


export default ([
	{
		path:        '/bank-details',
		title:       'Bank Details',
		component:   lazy(() => import('../../../components/views/BankDetails')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['bank_details_read'],
		orgTypes:    [orgTypes.DAX],
		routes:      []
	},
] as IRoutes[])