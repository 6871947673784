// AXIOS
import {apiClient} from "../../clients/api.client";
// HELPERS
import getBrand from "../../helpers/getBrand"


const { get, patch } = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default ({
    getCustomer:        (customerId: string) => get(`${URL()}/customers/${customerId}`),
    getCustomersAvatar: (customerId: string) => get(
        `${URL()}/customers/${customerId}/avatar`,
        { responseType: 'blob' },
    ),
    changeCustomerStatus:
        ({ customerId, status }: { customerId: string; status: number}) => 
            patch(`${URL()}/customers/${customerId}/status`, { status })
});
