// CORE
import { createSlice } from "@reduxjs/toolkit";
// SYNC ACTIONS
import asyncActions from "./asyncActions";
// TYPES
import { IRiskToleranceSettingsState } from "./index.D";


const initialState: IRiskToleranceSettingsState = {
  loading:  false,
  settings: {
    riskTolerance: [],
    allRisks:      [],
  },
};

const riskToleranceSettingsSlice = createSlice({
  name:     "riskToleranceSettings",
  initialState,
  reducers: {
    clear: (state) => {
      state.loading = false;
      state.settings = {
		riskTolerance: [],
		allRisks:      [],
	  };
    },
  },
  extraReducers: {
    [asyncActions.getRiskToleranceSettings.pending.type]: (state) => {
      state.loading = true;
    },
    [asyncActions.getRiskToleranceSettings.rejected.type]: (state) => {
      state.loading = false;
    },
    [asyncActions.getRiskToleranceSettings.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.settings = payload;
    },
    [asyncActions.updateRiskToleranceSettings.pending.type]: (state) => {
      state.loading = true;
    },
    [asyncActions.updateRiskToleranceSettings.rejected.type]: (state) => {
      state.loading = false;
    },
    [asyncActions.updateRiskToleranceSettings.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.loading = false;
      state.settings.riskTolerance = payload;
    },
  },
});

export default {
  ...riskToleranceSettingsSlice,
  asyncActions,
};
