import {createAsyncThunk} from '@reduxjs/toolkit';
import {storeAddErrorNotification} from '../../../helpers/errors';
import servicesCustomer from '../../../services/customer';
import { customerStatus } from './index.D';


export default ({
    getCustomer: createAsyncThunk('customers/getCustomer',
        async (params: string, thunkAPI) => await storeAddErrorNotification(
            () => servicesCustomer.getCustomer(params), thunkAPI, false)),
    getAvatar: createAsyncThunk('customer/avatar',
        async (params: string, thunkAPI) => await storeAddErrorNotification(
            () => servicesCustomer.getCustomersAvatar(params), thunkAPI, false)),
    changeCustomerStatus: createAsyncThunk('customer/status/change',
        async(params: {customerId: string, status: customerStatus}, thunkAPI) => await storeAddErrorNotification(
            () => servicesCustomer.changeCustomerStatus(params), thunkAPI, false,
        ))
});
