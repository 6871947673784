import {lazy} from "@loadable/component";
import {IRoutes} from "../routes.D";


export default ([
	{
		path:        '/price-plans',
		title:       'Price plans',
		component:   lazy(() => import('../../../components/views/PricePlans')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['price_plan_manage'],
		routes:      []
	},
] as IRoutes[])