export const jigzoPaths = [
	'/',
	'/dashboard',
	'/admins',
	'/admin/me',
	'/admins/:id',
	'/admins/create',
	'/admins/:id/edit',
	'/customers',
	'/customers/:customerId',
	'/customers/:customerId/edit',
	'/customers/:customerId/documents/:id',
	'/customers/:customerId/transfers/:id',
	'/customers/:customerId/beneficiaries/:id',
	'/businesses',
	'/businesses/:businessId',
	'/businesses/:businessId/edit',
	'/businesses/:businessId/beneficiaries/:id',
	'/business/:businessId/documents/:id',
	'/businesses/:businessId/business-contact/',
	'/businesses/:businessId/business-contact/:contactId',
	'/businesses/:businessId/business-contact/:id/edit',
	'/businesses/:businessId/business-contact/:contactId/documents/:id',
	'/businesses/:businessId/transfers/:id',
	'/documents',
	'/documents/:id',
	'/transfers',
	'/transfers/:id',
	'/batched-transfers',
	'/batched-transfers/:transferId',
	'/cards',
	'/customers/:customerId/cards/:id',
	'/businesses/:businessId/cards/:id',
	'/brands',
	'/margins-fees',
	// '/organizations-fees',
	'/crypto-margins-fees',
	'/transactions-margins-fees',
	'/transactions-costs',
	// '/logs',
	// '/logs/:id',
	'/supported-documents',
	'/supported-documents/:id',
	'/supported-documents/proofs/create',
	'/registration-documents',
	'/bank-details',
	'/settings',
	'/jurisdictions',
	'/customers/:customerId/kyc-result/:resultId',
	'/businesses/:businessId/business-contact/:contactId/kyc-result/:resultId',
	'/businesses/:businessId/kyi-result/:resultId',
	'/waiting-list',
	'/digital-assets',
	'/price-plans',
	'/business-activities',
	'/onboarding-requests',
	'/onboarding-requests/:requestId',
	'/questionnaire',
	'/organizations',
	'/organizations/:id',
	'/organizations/create',
	'/organizations/:id/edit',
]