// core
import { createSlice } from "@reduxjs/toolkit";

// async actions
import asyncActions from "./asyncActions";
import { defaultPagination } from "../search/pagination.D";


const initialState = {
  logs: {
    items: [],
    info:  defaultPagination,
  },
  isLoading: false,
  error:     "",
};
const batchedTransfersLogsSlice = createSlice({
  name:     "batchedTransfersLogs",
  initialState,
  reducers: {
    clearLogs: (state) => {
      state.logs = {
        items: [],
        info:  defaultPagination,
      };
    },
  },
  extraReducers: {
    [asyncActions.getTransfersLogs.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.logs = payload;
      state.isLoading = false;
      state.error = "";
    },
    [asyncActions.getTransfersLogs.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
    },
    [asyncActions.getTransfersLogs.rejected.type]: (
      state,
      { payload }
    ) => {
      state.isLoading = false;
      state.error = payload;
    },
    [asyncActions.loadMoreTransfersLogs.fulfilled.type]: (state, { payload }) => {
      state.logs.info = payload.info;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.logs.items = [...state.logs.items, ...payload.items];
      state.isLoading = false;
      state.error = "";
    },
    [asyncActions.loadMoreTransfersLogs.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
    },
    [asyncActions.loadMoreTransfersLogs.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export default {
  ...batchedTransfersLogsSlice,
  asyncActions,
};
