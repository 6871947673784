import {lazy} from "@loadable/component";
import {IRoutes} from "../routes.D";


export default ([
	{
		path:        '/businesses/:businessId/business-contact/:contactId',
		title:       'Contact details',
		component:   lazy(() => import('../../../components/views/BusinessContactDetail')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['businesses_read', 'businesses_report'],
		routes:      [
			{
				title:  'Businesses',
				path:   '/businesses',
				params: []
			},
			{
				title:  'Business Details',
				path:   '/businesses/:businessId',
				params: ['businessId']
			},
			{
				title:  'Contacts',
				path:   '/businesses/:businessId#contacts',
				params: ['businessId']
			},
		]
	},
	{
		path:        '/businesses/:businessId/business-contact/:id/edit',
		title:       'Edit Contact',
		component:   lazy(() => import('../../../components/modules/form/BusinessContactEditForm')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: [],
		routes:      [
			{
				title:  'Businesses',
				path:   '/businesses',
				params: []
			},
			{
				title:  'Business Details',
				path:   '/businesses/:businessId',
				params: ['businessId']
			},
			{
				title:  'Contacts',
				path:   '/businesses/:businessId#contacts',
				params: ['businessId']
			},
			{
				title:  'Contact Details',
				path:   '/businesses/:businessId/business-contact/:id',
				params: ['businessId', 'id']
			},
		]
	},
	{
		path:        '/businesses/:businessId/business-contact/:contactId/kyc-result/:resultId',
		title:       'KYC Result',
		component:   lazy(() => import('../../../components/views/KYCResult')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['sp_verification_read'],
		routes:      [
			{
				title:  'Businesses',
				path:   '/businesses',
				params: []
			},
			{
				title:  'Business Details',
				path:   '/businesses/:businessId',
				params: ['businessId']
			},
			{
				title:  'Contacts',
				path:   '/businesses/:businessId#contacts',
				params: ['businessId']
			},
			{
				title:  'Contact Details',
				path:   '/businesses/:businessId/business-contact/:id',
				params: ['businessId', 'id']
			},
		]
	},
	{
		path:        '/businesses/:businessId/kyi-result/:resultId',
		title:       'KYI Result',
		component:   lazy(() => import('../../../components/views/KYIResult')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['sp_verification_read'],
		routes:      [
			{
				title:  'Businesses',
				path:   '/businesses',
				params: []
			},
			{
				title:  'Business Details',
				path:   '/businesses/:businessId',
				params: ['businessId']
			},
		]
	},
] as IRoutes[]);