import {createSlice} from '@reduxjs/toolkit';
import asyncActions from "./asyncActions";
import {IBusinessActivitySlice} from "./index.D";


const initialState = {
	list:         [],
	listFetching: false,
	listLoading:  false,

	organizationConfig: [],
	orgLoading:         false,
} as IBusinessActivitySlice;

const businessActivitySlice = createSlice({
	name:     'businessActivity',
	initialState,
	reducers: {
		clearState: (state) => {
			state.list = [];
			state.listLoading = false;

			state.organizationConfig = [];
			state.orgLoading = false;
		}
	},
	extraReducers: {
		// GET ALL
		[asyncActions.getBusinessActivities.pending.type]: (state, action) => {
			state.listFetching = true;
		},
		[asyncActions.getBusinessActivities.rejected.type]: (state, action) => {
			state.listFetching = false;
		},
		[asyncActions.getBusinessActivities.fulfilled.type]: (state, action) => {
			state.listFetching = false;
			state.list = action.payload;
		},
		// CREATE
		[asyncActions.createBusinessActivities.pending.type]: (state, action) => {
			state.listLoading = true;
		},
		[asyncActions.createBusinessActivities.rejected.type]: (state, action) => {
			state.listLoading = false;
		},
		[asyncActions.createBusinessActivities.fulfilled.type]: (state, action) => {
			const newActivity = action.payload;
			state.list = [...state.list, newActivity];
			state.listLoading = false;
		},
		// UPDATE TITLE
		[asyncActions.updateBusinessActivities.pending.type]: (state, action) => {
			state.listLoading = true;
		},
		[asyncActions.updateBusinessActivities.rejected.type]: (state, action) => {
			state.listLoading = false;
		},
		[asyncActions.updateBusinessActivities.fulfilled.type]: (state, action) => {
			const updated = action.payload;
			state.listLoading = false;
			state.list = [...state.list].map((a) => a._id === updated._id ? updated : a);
		},

		// GET ORGANIZATION LIST
		[asyncActions.getBusinessActivitiesOrg.pending.type]: (state, action) => {
			state.orgLoading = true;
		},
		[asyncActions.getBusinessActivitiesOrg.rejected.type]: (state, action) => {
			state.orgLoading = false;
		},
		[asyncActions.getBusinessActivitiesOrg.fulfilled.type]: (state, action) => {
			state.orgLoading = false;
			state.organizationConfig = action.payload;
		},
	}
});

export default ({
	...businessActivitySlice,
	asyncActions
})