import {createSlice} from '@reduxjs/toolkit';
// TYPES
import {IProofSlice} from "./index.D"
// ACTIONS
import asyncActions from './asyncActions';
import {defaultPagination} from "../search/pagination.D";


const initialState = {
	proofs: {
		items: [],
		info:  defaultPagination,
	},
	unloadedProofs:  [],
	proof:           null,
	authProof:       null,
	isLoading:       false,
	documentsProofs: {
		items: [],
		info:  defaultPagination,
	},
} as IProofSlice;

const marginSlice = createSlice({
	name:     'margins',
	initialState,
	reducers: {
		clearProofs(state: IProofSlice) {
			state.proofs = {
				items: [],
				info:  defaultPagination
			}

			return state;
		},
		clearUnloadedProofs(state: IProofSlice) {
			state.unloadedProofs = []

			return state;
		},
		clearDocumentsProofs(state: IProofSlice) {
			state.proofs = {
				items: [],
				info:  defaultPagination
			}

			return state;
		},
		updateDocumentProof(state: IProofSlice, action) {
			const {id, data} = action.payload;

			state.documentsProofs.items = state.documentsProofs.items.map((proof)=>{
				if(proof._id === id) return {...proof, ...data};

				return proof;
			})

			return state;
		},
	},
	extraReducers: {
		[asyncActions.createVerificationProof.pending.type]: (state: IProofSlice) => {
			state.isLoading = true;
		},
		[asyncActions.createVerificationProof.fulfilled.type]: (state: IProofSlice) => {
			state.isLoading = false;
		},
		[asyncActions.createVerificationProof.rejected.type]: (state: IProofSlice) => {
			state.isLoading = false;
		},
		[asyncActions.getProofs.pending.type]: (state: IProofSlice) => {
			state.isLoading = true;
		},
		[asyncActions.getProofs.rejected.type]: (state: IProofSlice) => {
			state.isLoading = false;
		},
		[asyncActions.getProofs.fulfilled.type]: (state: IProofSlice, action) => {
			state.proofs = action.payload;
			state.isLoading = false;
		},
		[asyncActions.getProofById.fulfilled.type]: (state: IProofSlice, action) => {
			state.proof = action.payload;
			state.isLoading = false;
		},
		[asyncActions.updateProof.fulfilled.type]: (state: IProofSlice, action) => {
			state.proof = action.payload;
			state.isLoading = false;
		},
		[asyncActions.getAuthProofByType.fulfilled.type]: (state: IProofSlice, action) => {
			state.authProof = action.payload;
			state.isLoading = false;
		},
		[asyncActions.updateAuthProof.fulfilled.type]: (state: IProofSlice, action) => {
			state.authProof = action.payload;
			state.isLoading = false;
		},
		[asyncActions.getUnloadedProof.fulfilled.type]: (state: IProofSlice, action) => {
			state.unloadedProofs = action.payload;
			state.isLoading = false;
		},
		[asyncActions.getVerificationProofs.pending.type]: (state: IProofSlice) => {
			state.isLoading = true;
		},
		[asyncActions.getVerificationProofs.rejected.type]: (state: IProofSlice) => {
			state.isLoading = false;
		},
		[asyncActions.getVerificationProofs.fulfilled.type]: (state: IProofSlice, action) => {
			state.documentsProofs = action.payload;
			state.isLoading = false;
		},
		[asyncActions.deleteProofRequest.fulfilled.type]: (state: IProofSlice, action) => {
			state.documentsProofs.items = state.documentsProofs.items.filter((
				proof) => proof._id !== action.payload
			);
			state.documentsProofs.info.total_entries--;
			state.isLoading = false;
		},
	},
});

export default ({
	...marginSlice,
	asyncActions
});