import React, { FC, useEffect, useState, useCallback, useRef } from "react";
// HOOKS
import { useAppDispatch, useAppSelector } from "./app/hooks/redux.hook";
// STORE
import authModule from "./app/store/reducers/auth";
// COMPONENTS
import SessionExpiresModal from "./components/modals/SessionExpiresModal";


const countdownTime = 10;

const AuthActivity: FC = () => {
  const {
    isLoggedIn = false,
    lastActivityTime,
    inactive,
  } = useAppSelector((state) => state.auth);

  const countdownTimerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const activityInterval = useRef<ReturnType<typeof setInterval> | null>(null);

  const [currentTime, setCurrentTime] = useState<number | null>(
    localStorage.getItem("currentTime")
      ? +(localStorage.getItem("currentTime") ?? 0)
      : null
  );

  const idleTimeout = +(localStorage.getItem("idleTimeout") ?? 0);

  const dispatch = useAppDispatch();

  const resetTimer = useCallback(() => {
    dispatch(authModule.actions.setLastActivityTime(Date.now()));
  }, [lastActivityTime]);

  useEffect(() => {
    if (isLoggedIn) {
      window.addEventListener("click", resetTimer);
      window.addEventListener("keydown", resetTimer);
    }

    return () => {
      window.removeEventListener("click", resetTimer);
      window.removeEventListener("keydown", resetTimer);
    };
  }, [resetTimer, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(authModule.actions.setLastActivityTime(Date.now()));
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!inactive && isLoggedIn) {
      activityInterval.current = setInterval(() => {
        dispatch(
          authModule.asyncActions.updateSessionActivity(lastActivityTime)
        );
      }, 180000);
    }

    return () => {
      if (activityInterval.current) {
        clearInterval(activityInterval.current);
        activityInterval.current = null;
      }
    };
  }, [inactive, isLoggedIn, lastActivityTime]);

  useEffect(() => {
    if (isLoggedIn) {
      timerRef.current = setInterval(() => {
        const now = Date.now();
        if (
          now - (lastActivityTime || 0) - countdownTime * 1000 >= idleTimeout &&
          !inactive
        ) {
          dispatch(authModule.actions.setInactive(true));
        }
      }, 30000);
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [lastActivityTime, isLoggedIn, inactive]);

  useEffect(() => {
    if (inactive) {
      if (!currentTime) {
        setCurrentTime(countdownTime);
        localStorage.setItem("currentTime", countdownTime.toString());
      }
      countdownTimerRef.current = setInterval(() => {
        setCurrentTime((time) => {
          localStorage.setItem("currentTime", ((time || 0) - 1).toString());

          return (time || 0) - 1;
        });
      }, 1000);
    }

    return () => {
      if (countdownTimerRef.current) {
        clearInterval(countdownTimerRef.current);
        countdownTimerRef.current = null;
        setCurrentTime(null);
      }
    };
  }, [inactive]);

  useEffect(() => {
    if (currentTime === 0 && countdownTimerRef.current) {
      clearInterval(countdownTimerRef.current);
      countdownTimerRef.current = null;
      setCurrentTime(null);
      handleLogout();
    }
  }, [currentTime]);

  const handleCloseModal = () =>
    dispatch(authModule.actions.setInactive(false));

  const onContinueSession = () => {
    dispatch(authModule.actions.setInactive(false));
    dispatch(authModule.asyncActions.updateSession());
    handleCloseModal();
    localStorage.removeItem("currentTime");
  };

  const handleLogout = async () => {
    handleCloseModal();
    await dispatch(authModule.asyncActions.logout());
    window.location.reload();
  };

  return (
    <>
      <SessionExpiresModal
        onContinue={onContinueSession}
        onLogout={handleLogout}
        isOpen={inactive}
        countdownTime={currentTime}
      />
    </>
  );
};

export default AuthActivity;
