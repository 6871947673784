import {createSlice} from '@reduxjs/toolkit';
// TYPES
import {IFeesSlice} from "./index.D"
// ACTIONS
import asyncActions from './asyncActions';


const initialState = {
	fees: {
		items: [],
		info:  {
			current_page:   1,
			next_page:      null,
			order:          null,
			order_asc_desc: "asc",
			per_page:       10,
			previous_page:  null,
			total_entries:  0,
			total_pages:    1,
		}
	},
	openingFees: {master: []},
	refundFees:  {master: []},
	baseFee:     null,
	isLoading:   false,
} as IFeesSlice

const marginSlice = createSlice({
	name:          'margins',
	initialState,
	reducers:      {},
	extraReducers: {
		[asyncActions.getFees.fulfilled.type]: (state: IFeesSlice, action) => {
			state.fees = action.payload;
			state.isLoading = false;
		},
		[asyncActions.deleteFee.fulfilled.type]: (state: IFeesSlice, action) => {
			state.fees.items = state.fees.items.filter(fee => fee._id !== action.meta.arg)
			state.isLoading = false;
		},
		[asyncActions.getBaseFee.fulfilled.type]: (state: IFeesSlice, action) => {
			state.baseFee = action.payload;
			state.isLoading = false;
		},
		[asyncActions.createBaseFee.fulfilled.type]: (state: IFeesSlice, action) => {
			state.baseFee = action.payload;
			state.isLoading = false;
		},
		[asyncActions.updateBaseFee.fulfilled.type]: (state: IFeesSlice, action) => {
			state.baseFee = action.payload;
			state.isLoading = false;
		},
		[asyncActions.getOpeningFees.fulfilled.type]: (state: IFeesSlice, action) => {
			const { meta: { arg } } = action;
			const key = arg?.pricePlan ? arg.pricePlan.toLowerCase() : 'master';

			state.openingFees[key] = action.payload;
			state.isLoading = false;
		},
		// REFUND FEES
		[asyncActions.getRefundFees.fulfilled.type]: (state: IFeesSlice, action) => {
			const { meta: { arg } } = action;
			const key  = arg?.basePriceTier ? arg.basePriceTier.toLowerCase() : 'master';

			state.refundFees[key] = action.payload;
		}
	},
});

export default ({
	...marginSlice,
	asyncActions
});