// core
import { createSlice } from "@reduxjs/toolkit";

// async actions
import asyncActions from "./asyncActions";
import { defaultPagination } from "../search/pagination.D";


const initialState = {
  transactions: {
    items: [],
    info:  defaultPagination,
  },
  isLoading: false,
  error:     "",
};
const highRiskTransactionsSlice = createSlice({
  name:     "highRiskTransactions",
  initialState,
  reducers: {
    clearTransactions: (state) => {
      state.transactions = {
        items: [],
        info:  defaultPagination,
      };
    },
  },
  extraReducers: {
    [asyncActions.loadMoreTransactions.fulfilled.type]: (state, { payload }) => {
      state.transactions.info = payload.info;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.transactions.items = [...state.transactions.items, ...payload.items];
      state.isLoading = false;
      state.error = "";
    },
    [asyncActions.loadMoreTransactions.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
    },
    [asyncActions.loadMoreTransactions.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [asyncActions.getTransactions.fulfilled.type]: (state, { payload }) => {
      state.transactions = payload;
      state.isLoading = false;
      state.error = "";
    },
    [asyncActions.getTransactions.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
    },
    [asyncActions.getTransactions.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export default {
  ...highRiskTransactionsSlice,
  asyncActions,
};
