// HELPERS
import objectToURL from "../../helpers/objectToURL";
import getBrand from "../../helpers/getBrand";
// TYPES
import {IDocument, IParams} from "app/store/reducers/documents/index.D";
// AXIOS
import {apiClient} from "../../clients/api.client";


const {get, put, delete: del, post} = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default ({
	getAllDocuments:     (params: IParams): Promise<string> => get(`${URL()}/documents?${objectToURL(params)}`),
	getByCustomer:       (customerId: string) => get(`${URL()}/customers/${customerId}/documents`),
	read:                (documentsId: string) => get(`${URL()}/documents/${documentsId}`),
	deleteDocument:      (documentsId: string) => del(`${URL()}/documents/${documentsId}`),
	deleteManyDocuments: () => del(`${URL()}/documents`),
	updateDocument:      ({_id, ...params}: Partial<IDocument>) => put(`${URL()}/documents/${_id}`, params),
	downloadDocument:    ({id, name}: IParams) => get(`${URL()}/documents/${id}/${name}`, {
		responseType: 'blob'
	}),
	viewDocument:           ({id, name}: IParams) => get(`${URL()}/documents/view/${id}/${name}`),
	uploadBusinessDocument: (id: string, data: FormData) => post(`${URL()}/business/${id}/documents`, data),
	uploadCustomerDocument: (id: string, data: FormData) => post(`${URL()}/customers/${id}/documents`, data),
});
