import {createAsyncThunk} from '@reduxjs/toolkit';
// HELPERS
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICES
import rulesService from '../../../services/manageCompliance';
// TYPES
import {IQueriesRules} from "./index.D"


export default ({
	getRules: createAsyncThunk('rules/get',
		async (params: IQueriesRules, thunkAPI) => await storeAddErrorNotification(
			() => rulesService.get(params), thunkAPI, false)),
});
