import {createSlice} from '@reduxjs/toolkit';
// TYPES
import {IManageComplianceSlice} from "./index.D"
// ACTIONS
import asyncActions from './asyncActions';


const initialState = {
	rules: {
		items: [],
		info:  {
			current_page:   1,
			next_page:      null,
			order:          null,
			order_asc_desc: "asc",
			per_page:       10,
			previous_page:  null,
			total_entries:  0,
			total_pages:    1,
		}
	},
	isLoading: false,
} as IManageComplianceSlice

const riskAlertsSlice = createSlice({
	name:     'manageCompliance',
	initialState,
	reducers: {
		clearRules(state:IManageComplianceSlice){
			state.rules = {
				items: [],
				info:  {
					current_page:   1,
					next_page:      null,
					order:          null,
					order_asc_desc: "asc",
					per_page:       10,
					previous_page:  null,
					total_entries:  0,
					total_pages:    1,
				}
			}

			return state;
		}
	},
	extraReducers: {
		[asyncActions.getRules.fulfilled.type]: (state: IManageComplianceSlice, action) => {
			state.rules = action.payload;
			state.isLoading = false;
		},
		[asyncActions.getRules.pending.type]: (state: IManageComplianceSlice) => {
			state.isLoading = true;
		},
		[asyncActions.getRules.rejected.type]: (state: IManageComplianceSlice) => {
			state.isLoading = false;
		},
	},
});

export default ({
	...riskAlertsSlice,
	asyncActions
});