import {createAsyncThunk} from '@reduxjs/toolkit';
// HELPERS
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICES
import maintenanceFeesService from '../../../services/maintenanceFees';
// TYPES
import {IFeeMaintenanceCreate, IQueriesMaintenanceFees} from "./index.D";


export default ({
	getMaintenanceFees: createAsyncThunk('maintenanceFees/get',
		async (params: IQueriesMaintenanceFees, thunkAPI) => await storeAddErrorNotification(
			() => maintenanceFeesService.getMaintenanceFees(params), thunkAPI, false)),
	createMaintenanceFee: createAsyncThunk('maintenanceFees/create',
		async (data: IFeeMaintenanceCreate, thunkAPI) => await storeAddErrorNotification(
			() => maintenanceFeesService.createMaintenanceFee(data), thunkAPI, false)),
	updateMaintenanceFee: createAsyncThunk('maintenanceFees/update',
		async (data:{id: string, data: Partial<IFeeMaintenanceCreate>}, thunkAPI) => await storeAddErrorNotification(
			() => maintenanceFeesService.updateMaintenanceFee(data), thunkAPI, false)),
	deleteMaintenanceFee: createAsyncThunk('maintenanceFees/delete',
		async (id:string, thunkAPI) => await storeAddErrorNotification(
			() => maintenanceFeesService.deleteMaintenanceFee(id), thunkAPI, false)),
})