// CORE
import { createSlice } from "@reduxjs/toolkit";
// ASYNC ACTIONS
import asyncActions from "./asyncActions";
// TYPES
import { IBasePriceTiersState } from "./index.D";


const initialState: IBasePriceTiersState = {
  loading: false,
  tiers:   [],
};

const basePriceTiersSlice = createSlice({
  name:     "basePriceTiers",
  initialState,
  reducers: {
    clear: (state) => {
      (state.loading = false), (state.tiers = []);
    },
  },
  extraReducers: {
    [asyncActions.getBasePriceTiers.fulfilled.type]: (state, { payload }) => {
      state.loading = false;
      state.tiers = payload;
    },
    [asyncActions.getBasePriceTiers.pending.type]: (state) => {
      state.loading = true;
    },
    [asyncActions.getBasePriceTiers.rejected.type]: (state) => {
      state.loading = false;
    },
  },
});

export default {
  ...basePriceTiersSlice,
  asyncActions,
};
