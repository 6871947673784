// AXIOS
import getBrand from "../../helpers/getBrand";
import { apiClient } from "../../clients/api.client";
// TYPES
import objectToURL from "../../helpers/objectToURL";


const { get } = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default {
  getTransfersLogs: (params?: Record<string, any>) =>
    get(`${URL()}/kraken-logs?${objectToURL(params)}`),
};
