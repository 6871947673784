// core
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// async actions
import asyncActions from "./asyncActions";
import { defaultPagination } from "../search/pagination.D";


const initialState = {
  batchedTransfer: {},
  transactions:    {
    items: [],
    info:  defaultPagination,
  },
  isLoading: false,
  error:     "",
};
const batchedTransferSlice = createSlice({
  name:     "batchedTransfer",
  initialState,
  reducers: {
    clearTransactions: (state) => {
      state.transactions = {
        items: [],
        info:  defaultPagination,
      };
      state.batchedTransfer = {};
    },
  },
  extraReducers: {
    [asyncActions.getBatchedTransferTransactions.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.transactions = payload;
      state.isLoading = false;
      state.error = "";
    },
    [asyncActions.getBatchedTransferTransactions.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
    },
    [asyncActions.getBatchedTransferTransactions.rejected.type]: (
      state,
      { payload }
    ) => {
      state.isLoading = false;
      state.error = payload;
    },
    [asyncActions.getBatchesTransfer.fulfilled.type]: (state, { payload }) => {
      state.batchedTransfer = payload;
      state.isLoading = false;
      state.error = "";
    },
    [asyncActions.getBatchesTransfer.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
    },
    [asyncActions.getBatchesTransfer.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [asyncActions.loadMoreTransferTransactions.fulfilled.type]: (state, { payload }) => {
      state.transactions.info = payload.info;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.transactions.items = [...state.transactions.items, ...payload.items];

      state.isLoading = false;
      state.error = "";
    },
    [asyncActions.loadMoreTransferTransactions.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
    },
    [asyncActions.loadMoreTransferTransactions.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [asyncActions.executeBulkTransfer.fulfilled.type]: (state, { payload }) => {
      state.batchedTransfer = payload;
      state.isLoading = false;
      state.error = "";
    },
    [asyncActions.executeBulkTransfer.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
    },
    [asyncActions.executeBulkTransfer.rejected.type]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export default {
  ...batchedTransferSlice,
  asyncActions,
};
