// Fonts Bold
import BaiJamJureeBold from './family/BaiJamjuree-Bold.ttf';
import BaiJamJureeBoldWoff from './family/BaiJamjuree-Bold.woff';
import BaiJamJureeBoldWoff2 from './family/BaiJamjuree-Bold.woff2';
import BaiJamJureeBoldEot from './family/BaiJamjuree-Bold.eot';

// // Fonts medium
import BaiJamJureeMedium from "./family/BaiJamjuree-Medium.ttf";
import BaiJamJureeMediumWoff from './family/BaiJamjuree-Medium.woff';
import BaiJamJureeMediumWoff2 from './family/BaiJamjuree-Medium.woff2';
import BaiJamJureeMediumEot from './family/BaiJamjuree-Medium.eot';

// // Fonts regular
import BaiJamJureeRegular from './family/BaiJamjuree-Regular.ttf';
import BaiJamJureeRegularWoff from './family/BaiJamjuree-Regular.woff';
import BaiJamJureeRegularWoff2 from './family/BaiJamjuree-Regular.woff2';
import BaiJamJureeRegularEot from './family/BaiJamjuree-Regular.eot';


const fonts = [
    {
        fontFamily: 'Bai Jamjuree',
        fontStyle:  'normal',
        fontWeight: 700,
        src:        `
        url(${BaiJamJureeBoldWoff2}) format('woff2'),
        url(${BaiJamJureeBoldWoff}) format('woff'),
        url(${BaiJamJureeBold}) format('ttf'),
        url(${BaiJamJureeBoldEot + '?#iefix'}) format('embedded-opentype')`,
        fontDisplay: 'swap',
    },
    {
        fontFamily: 'Bai Jamjuree',
        fontStyle:  'normal',
        fontWeight: 500,
        src:        `
        url(${BaiJamJureeMediumWoff2}) format('woff2'),
        url(${BaiJamJureeMediumWoff}) format('woff'),
        url(${BaiJamJureeMediumEot + '?#iefix'}) format('embedded-opentype'),
        url(${BaiJamJureeMedium}) format('ttf')`,
        fontDisplay: 'swap',
    },
    {
        fontFamily: 'Bai Jamjuree',
        fontStyle:  'normal',
        fontWeight: 400,
        src:        `
        url(${BaiJamJureeRegularWoff2}) format('woff2'),
        url(${BaiJamJureeRegularWoff}) format('woff'),
        url(${BaiJamJureeRegularEot + '?#iefix'}) format('embedded-opentype'),
        url(${BaiJamJureeRegular}) format('ttf')`,
        fontDisplay: 'swap',
    },
];

export {fonts};