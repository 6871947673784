import {lazy} from "@loadable/component";
import {IRoutes} from "../routes.D";


export default ([
	{
		path:        '/business-activities',
		title:       'Business activities',
		component:   lazy(() => import('../../../components/views/BusinessActivity')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['business_activity_manage'],
		routes:      [],
	}
] as IRoutes[]);