// core
import { createAsyncThunk }          from '@reduxjs/toolkit';

// services
import adminService                  from '../../../services/Admin';


export default ({
    getMe: createAsyncThunk('/admin', 
        async () => await adminService.getMe()),
});