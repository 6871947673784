// API
import {apiClient} from "../../clients/api.client";
// HELPERS
import getBrand from "../../helpers/getBrand"
import objectToURL from "../../helpers/objectToURL";
// TYPES
import {IEditDigitalAssets} from "../../store/reducers/digitalAssets/index.D";


const {get, post, delete: del, patch} = apiClient;
const URL = () => `organizations/${getBrand(true)}`;

export default ({
	get:               (params: Record<string, any>) => get(`${URL()}/digital-assets?${objectToURL(params)}`),
	getAll:            () => get(`${URL()}/digital-assets/all`),
	setAvailable:      (codes: string[]) => post(`${URL()}/digital-assets/available`, {codes}),
	setUnavailable:    (codes: string) => del(`${URL()}/digital-assets/unavailable?codes=${codes}`),
	editDigitalAssets: (data: IEditDigitalAssets) => patch(`${URL()}/digital-assets`, data),
});