// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// HELPERS
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICES
import servicesTransactions from '../../../services/transactions';


export default {
	getTransactionsCryptoByTransfer: createAsyncThunk('transactionsCrypto/getTransactionsCrypto',
		async (params:{id:string}, thunkAPI) => await storeAddErrorNotification(
			() => servicesTransactions.getTransactionsCryptoByTransfer(params), thunkAPI, false)),
	getTransactionsCryptoByCustomer: createAsyncThunk('transactionsCrypto/getTransactionsCryptoCustomer',
		async (params:{id:string, params: Record<string, unknown>}, thunkAPI) =>
			await storeAddErrorNotification(
			() => servicesTransactions.getTransactionsCryptoByCustomer(params), thunkAPI, false)),
};