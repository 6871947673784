// CORE
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// ASYNC ACTIONS
import asyncActions from "./asyncActions";
// TYPES
import {IForecastingDaxSlice, IChangePayload} from "./index.D";
// HELPERS
import {calculateRowResult} from "./helpers";
import {getProjectedEarnings} from "../../../../components/modules/ForecastDax/Sidebar/helpers";


const initialState = {
	data:         [],
	result:       [],
	editable:     [],
	currentTotal: 0,
	loading:      false,
} as IForecastingDaxSlice;

const forecastingDaxSlice = createSlice({
	name:     'forecastingDax',
	initialState,
	reducers: {
		changeValue: (state, {payload}: PayloadAction<IChangePayload>) => {
			const {value, valueType, type, operation} = payload;
			const idx = state.editable.findIndex((r) => r.type === type && r.operation === operation);

			if(idx >= 0) {
				if(valueType === 'amount') {
					state.editable[idx].averageSum = value;
				}

				if(valueType === 'count') {
					state.editable[idx].count = value;
				}

				if(valueType === 'value') {
					state.editable[idx].brand.value = value;
				}

				if(valueType === 'percent') {
					state.editable[idx].brand.percent = value;
				}

				const result = calculateRowResult(state.editable[idx]);

				state.editable[idx].result = result;
			}
		},
	},
	extraReducers: {
		[asyncActions.getForecastData.fulfilled.type]: (state, action) => {
			state.data = action.payload.data;
			state.result = action.payload.config;
			state.editable = action.payload.config;
			state.currentTotal = getProjectedEarnings(action.payload.data);
		},
		[asyncActions.saveForecastConfig.fulfilled.type]: (state) => {
			state.result = [...state.editable];
		}
	}
});

export default ({
	...forecastingDaxSlice,
	asyncActions
})