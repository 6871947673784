// AXIOS
import {apiClient} from "../../clients/api.client";
// HELPERS
import getBrand from "../../helpers/getBrand";
// TYPES
import {IBusinessActivity, IBusinessActivityCreate} from "../../store/reducers/businessActivity/index.D";


const { get, post, patch } = apiClient;

const masterUrl = () => `organizations/${getBrand(true)}/business-activities`;

const URL = () => `organizations/${getBrand()}/business-activities`;

export default ({
	getBusinessActivities:  () => get(masterUrl()),
	createBusinessActivity: (data: IBusinessActivityCreate) => post(masterUrl(), data),
	updateBusinessActivity: ({_id, ...payload}: IBusinessActivity) => patch(`${masterUrl()}/${_id}`, payload),

	getBusinessActivitiesOrg: () => get(URL()),
});