// CORE
import {createSlice} from '@reduxjs/toolkit';
// TYPES
import {IExternalAssetsSlice} from './index.D';
// ACTIONS
import asyncActions from './asyncActions';


const initialState: IExternalAssetsSlice = {
	isLoading: false,
  items:     [],
};

const cryptoFeesMarginsSlice = createSlice({
	name:     'cryptoFeesMargins',
	initialState,
	reducers: {
		clearExternalAssets: (state) => {
			state.items = [];

			return state;
		},
	},
	extraReducers: {
		[asyncActions.getExternalAssets.pending.type]: (state) => {
			state.isLoading = true;
		},
    [asyncActions.getExternalAssets.rejected.type]: (state) => {
			state.isLoading = false;
		},
		[asyncActions.getExternalAssets.fulfilled.type]: (state, { payload }) => {
			state.isLoading = false;
      state.items = payload;
		},
	},
});

export default ({
	...cryptoFeesMarginsSlice,
	asyncActions
})