// CORE
import { createAsyncThunk } from "@reduxjs/toolkit";
// HELPERS
import { storeAddErrorNotification } from "../../../helpers/errors";
// SERVICE
import basePriceTiersService from "../../../services/basePriceTiers";


export default {
  getBasePriceTiers: createAsyncThunk(
    "basePriceTiers/get",
    async (params, thunkAPI) =>
      await storeAddErrorNotification(
        () => basePriceTiersService.getBasePriceTiers(),
        thunkAPI,
      )
  ),
};
