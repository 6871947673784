import {createSlice} from '@reduxjs/toolkit';
import {defaultPagination} from "../search/pagination.D";
// TYPES
import {IFeeRegularSlice} from "./index.D";
// ASYNC ACTIONS
import asyncActions from "./asyncActions";


const initialState = {
	regularFees: {
		items: [],
		info:  defaultPagination,
	},
	isLoading: false,
} as IFeeRegularSlice;

const feeRegularSlice = createSlice({
	name:     'feeRegular',
	initialState,
	reducers: {
		clearState: (state) => {
			state = initialState;
		}
	},
	extraReducers: {
		[asyncActions.getCustomerFees.fulfilled.type]: (state, action) => {
			state.regularFees = action.payload;
			state.isLoading = false;
		}
	}
});

export default ({
	...feeRegularSlice,
	asyncActions,
})