import {lazy} from "@loadable/component";
import {IRoutes} from "../routes.D";


export default ([
	{
		path:        '/limits',
		title:       'Daily Limits',
		component:   lazy(() => import('../../../components/views/DailyLimits')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['limit_read'],
		routes:      []
	},
] as IRoutes[]);