// TYPES
import {
	IForecastData,
	IForecastResultData,
	IForecastingSimple,
	IForecastResultItem,
	clientType,
	forecastOperation,
	IForecastingServiceFeeData,
} from "../../../../../app/store/reducers/forecasting/index.D";
import {RiskLevels} from "../../../../../app/store/reducers/customer/index.D";


export const getResultItem = (data: IForecastingSimple): IForecastResultItem => {
	const {brand: {percent, value}, count, averageSum} = data;
	const totalSum = +(averageSum * count).toFixed(2);
	const result = +((totalSum * percent / 100) + (value * count)).toFixed(2);

	return {
		totalSum,
		result,
		value,
		percent,
		count,
		amount: averageSum,
	};
}

export const getServiceResultItem = (data: IForecastingServiceFeeData): IForecastingServiceFeeData => {
	const amountTotal = +(data.amount * data.count).toFixed(2);

	return {
		...data,
		amountTotal
	};
}

/* eslint-disable max-len */
export default (data: IForecastData): IForecastResultData => {

	return {
		[clientType.customer]: {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			[RiskLevels.low]: {
				conversion: {
					[forecastOperation.faster]:            getResultItem(data[clientType.customer][RiskLevels.low]['conversion'][forecastOperation.faster]),
					[forecastOperation.sepa]:              getResultItem(data[clientType.customer][RiskLevels.low]['conversion'][forecastOperation.sepa]),
					[forecastOperation.virtualConversion]: getResultItem(data[clientType.customer][RiskLevels.low]['conversion'][forecastOperation.virtualConversion]),
				},
				funding: {
					[forecastOperation.faster]:     getResultItem(data[clientType.customer][RiskLevels.low]['funding'][forecastOperation.faster]),
					[forecastOperation.sepa]:       getResultItem(data[clientType.customer][RiskLevels.low]['funding'][forecastOperation.sepa]),
					[forecastOperation.swiftTier1]: getResultItem(data[clientType.customer][RiskLevels.low]['funding'][forecastOperation.swiftTier1]),
					[forecastOperation.swiftTier2]: getResultItem(data[clientType.customer][RiskLevels.low]['funding'][forecastOperation.swiftTier2]),
					[forecastOperation.swiftTier3]: getResultItem(data[clientType.customer][RiskLevels.low]['funding'][forecastOperation.swiftTier3]),
					[forecastOperation.swiftTier4]: getResultItem(data[clientType.customer][RiskLevels.low]['funding'][forecastOperation.swiftTier4]),
				},
				payment: {
					[forecastOperation.faster]:     getResultItem(data[clientType.customer][RiskLevels.low]['payment'][forecastOperation.faster]),
					[forecastOperation.sepa]:       getResultItem(data[clientType.customer][RiskLevels.low]['payment'][forecastOperation.sepa]),
					[forecastOperation.swiftTier1]: getResultItem(data[clientType.customer][RiskLevels.low]['payment'][forecastOperation.swiftTier1]),
					[forecastOperation.swiftTier2]: getResultItem(data[clientType.customer][RiskLevels.low]['payment'][forecastOperation.swiftTier2]),
					[forecastOperation.swiftTier3]: getResultItem(data[clientType.customer][RiskLevels.low]['payment'][forecastOperation.swiftTier3]),
					[forecastOperation.swiftTier4]: getResultItem(data[clientType.customer][RiskLevels.low]['payment'][forecastOperation.swiftTier4]),
				},
				'balance-EUR':   getServiceResultItem(data[clientType.customer][RiskLevels.low]['balance-EUR']),
				'balance-GBP':   getServiceResultItem(data[clientType.customer][RiskLevels.low]['balance-GBP']),
				'balance-MULTI': getServiceResultItem(data[clientType.customer][RiskLevels.low]['balance-MULTI']),
				opening:         getServiceResultItem(data[clientType.customer][RiskLevels.low].opening),
				subscription:    getServiceResultItem(data[clientType.customer][RiskLevels.low].subscription),
			},
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			[RiskLevels.medium]: {
				conversion: {
					[forecastOperation.faster]:            getResultItem(data[clientType.customer][RiskLevels.medium]['conversion'][forecastOperation.faster]),
					[forecastOperation.sepa]:              getResultItem(data[clientType.customer][RiskLevels.medium]['conversion'][forecastOperation.sepa]),
					[forecastOperation.virtualConversion]: getResultItem(data[clientType.customer][RiskLevels.medium]['conversion'][forecastOperation.virtualConversion]),
				},
				funding: {
					[forecastOperation.faster]:     getResultItem(data[clientType.customer][RiskLevels.medium]['funding'][forecastOperation.faster]),
					[forecastOperation.sepa]:       getResultItem(data[clientType.customer][RiskLevels.medium]['funding'][forecastOperation.sepa]),
					[forecastOperation.swiftTier1]: getResultItem(data[clientType.customer][RiskLevels.medium]['funding'][forecastOperation.swiftTier1]),
					[forecastOperation.swiftTier2]: getResultItem(data[clientType.customer][RiskLevels.medium]['funding'][forecastOperation.swiftTier2]),
					[forecastOperation.swiftTier3]: getResultItem(data[clientType.customer][RiskLevels.medium]['funding'][forecastOperation.swiftTier3]),
					[forecastOperation.swiftTier4]: getResultItem(data[clientType.customer][RiskLevels.medium]['funding'][forecastOperation.swiftTier4]),
				},
				payment: {
					[forecastOperation.faster]:     getResultItem(data[clientType.customer][RiskLevels.medium]['payment'][forecastOperation.faster]),
					[forecastOperation.sepa]:       getResultItem(data[clientType.customer][RiskLevels.medium]['payment'][forecastOperation.sepa]),
					[forecastOperation.swiftTier1]: getResultItem(data[clientType.customer][RiskLevels.medium]['payment'][forecastOperation.swiftTier1]),
					[forecastOperation.swiftTier2]: getResultItem(data[clientType.customer][RiskLevels.medium]['payment'][forecastOperation.swiftTier2]),
					[forecastOperation.swiftTier3]: getResultItem(data[clientType.customer][RiskLevels.medium]['payment'][forecastOperation.swiftTier3]),
					[forecastOperation.swiftTier4]: getResultItem(data[clientType.customer][RiskLevels.medium]['payment'][forecastOperation.swiftTier4]),
				},
				'balance-EUR':   getServiceResultItem(data[clientType.customer][RiskLevels.medium]['balance-EUR']),
				'balance-GBP':   getServiceResultItem(data[clientType.customer][RiskLevels.medium]['balance-GBP']),
				'balance-MULTI': getServiceResultItem(data[clientType.customer][RiskLevels.medium]['balance-MULTI']),
				opening:         getServiceResultItem(data[clientType.customer][RiskLevels.medium].opening),
				subscription:    getServiceResultItem(data[clientType.customer][RiskLevels.medium].subscription),
			},
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			[RiskLevels.high]: {
				conversion: {
					[forecastOperation.faster]:            getResultItem(data[clientType.customer][RiskLevels.high]['conversion'][forecastOperation.faster]),
					[forecastOperation.sepa]:              getResultItem(data[clientType.customer][RiskLevels.high]['conversion'][forecastOperation.sepa]),
					[forecastOperation.virtualConversion]: getResultItem(data[clientType.customer][RiskLevels.high]['conversion'][forecastOperation.virtualConversion]),
				},
				funding: {
					[forecastOperation.faster]:     getResultItem(data[clientType.customer][RiskLevels.high]['funding'][forecastOperation.faster]),
					[forecastOperation.sepa]:       getResultItem(data[clientType.customer][RiskLevels.high]['funding'][forecastOperation.sepa]),
					[forecastOperation.swiftTier1]: getResultItem(data[clientType.customer][RiskLevels.high]['funding'][forecastOperation.swiftTier1]),
					[forecastOperation.swiftTier2]: getResultItem(data[clientType.customer][RiskLevels.high]['funding'][forecastOperation.swiftTier2]),
					[forecastOperation.swiftTier3]: getResultItem(data[clientType.customer][RiskLevels.high]['funding'][forecastOperation.swiftTier3]),
					[forecastOperation.swiftTier4]: getResultItem(data[clientType.customer][RiskLevels.high]['funding'][forecastOperation.swiftTier4]),
				},
				payment: {
					[forecastOperation.faster]:     getResultItem(data[clientType.customer][RiskLevels.high]['payment'][forecastOperation.faster]),
					[forecastOperation.sepa]:       getResultItem(data[clientType.customer][RiskLevels.high]['payment'][forecastOperation.sepa]),
					[forecastOperation.swiftTier1]: getResultItem(data[clientType.customer][RiskLevels.high]['payment'][forecastOperation.swiftTier1]),
					[forecastOperation.swiftTier2]: getResultItem(data[clientType.customer][RiskLevels.high]['payment'][forecastOperation.swiftTier2]),
					[forecastOperation.swiftTier3]: getResultItem(data[clientType.customer][RiskLevels.high]['payment'][forecastOperation.swiftTier3]),
					[forecastOperation.swiftTier4]: getResultItem(data[clientType.customer][RiskLevels.high]['payment'][forecastOperation.swiftTier4]),
				},
				'balance-EUR':   getServiceResultItem(data[clientType.customer][RiskLevels.high]['balance-EUR']),
				'balance-GBP':   getServiceResultItem(data[clientType.customer][RiskLevels.high]['balance-GBP']),
				'balance-MULTI': getServiceResultItem(data[clientType.customer][RiskLevels.high]['balance-MULTI']),
				opening:         getServiceResultItem(data[clientType.customer][RiskLevels.high].opening),
				subscription:    getServiceResultItem(data[clientType.customer][RiskLevels.high].subscription),
			},
		},
		[clientType.business]: {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			[RiskLevels.low]: {
				conversion: {
					[forecastOperation.faster]:            getResultItem(data[clientType.business][RiskLevels.low]['conversion'][forecastOperation.faster]),
					[forecastOperation.sepa]:              getResultItem(data[clientType.business][RiskLevels.low]['conversion'][forecastOperation.sepa]),
					[forecastOperation.virtualConversion]: getResultItem(data[clientType.business][RiskLevels.low]['conversion'][forecastOperation.virtualConversion]),
				},
				funding: {
					[forecastOperation.faster]:     getResultItem(data[clientType.business][RiskLevels.low]['funding'][forecastOperation.faster]),
					[forecastOperation.sepa]:       getResultItem(data[clientType.business][RiskLevels.low]['funding'][forecastOperation.sepa]),
					[forecastOperation.swiftTier1]: getResultItem(data[clientType.business][RiskLevels.low]['funding'][forecastOperation.swiftTier1]),
					[forecastOperation.swiftTier2]: getResultItem(data[clientType.business][RiskLevels.low]['funding'][forecastOperation.swiftTier2]),
					[forecastOperation.swiftTier3]: getResultItem(data[clientType.business][RiskLevels.low]['funding'][forecastOperation.swiftTier3]),
					[forecastOperation.swiftTier4]: getResultItem(data[clientType.business][RiskLevels.low]['funding'][forecastOperation.swiftTier4]),
				},
				payment: {
					[forecastOperation.faster]:     getResultItem(data[clientType.business][RiskLevels.low]['payment'][forecastOperation.faster]),
					[forecastOperation.sepa]:       getResultItem(data[clientType.business][RiskLevels.low]['payment'][forecastOperation.sepa]),
					[forecastOperation.swiftTier1]: getResultItem(data[clientType.business][RiskLevels.low]['payment'][forecastOperation.swiftTier1]),
					[forecastOperation.swiftTier2]: getResultItem(data[clientType.business][RiskLevels.low]['payment'][forecastOperation.swiftTier2]),
					[forecastOperation.swiftTier3]: getResultItem(data[clientType.business][RiskLevels.low]['payment'][forecastOperation.swiftTier3]),
					[forecastOperation.swiftTier4]: getResultItem(data[clientType.business][RiskLevels.low]['payment'][forecastOperation.swiftTier4]),
				},
				'balance-EUR':   getServiceResultItem(data[clientType.business][RiskLevels.low]['balance-EUR']),
				'balance-GBP':   getServiceResultItem(data[clientType.business][RiskLevels.low]['balance-GBP']),
				'balance-MULTI': getServiceResultItem(data[clientType.business][RiskLevels.low]['balance-MULTI']),
				opening:         getServiceResultItem(data[clientType.business][RiskLevels.low].opening),
				subscription:    getServiceResultItem(data[clientType.business][RiskLevels.low].subscription),
			},
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			[RiskLevels.medium]: {
				conversion: {
					[forecastOperation.faster]:            getResultItem(data[clientType.business][RiskLevels.medium]['conversion'][forecastOperation.faster]),
					[forecastOperation.sepa]:              getResultItem(data[clientType.business][RiskLevels.medium]['conversion'][forecastOperation.sepa]),
					[forecastOperation.virtualConversion]: getResultItem(data[clientType.business][RiskLevels.medium]['conversion'][forecastOperation.virtualConversion]),
				},
				funding: {
					[forecastOperation.faster]:     getResultItem(data[clientType.business][RiskLevels.medium]['funding'][forecastOperation.faster]),
					[forecastOperation.sepa]:       getResultItem(data[clientType.business][RiskLevels.medium]['funding'][forecastOperation.sepa]),
					[forecastOperation.swiftTier1]: getResultItem(data[clientType.business][RiskLevels.medium]['funding'][forecastOperation.swiftTier1]),
					[forecastOperation.swiftTier2]: getResultItem(data[clientType.business][RiskLevels.medium]['funding'][forecastOperation.swiftTier2]),
					[forecastOperation.swiftTier3]: getResultItem(data[clientType.business][RiskLevels.medium]['funding'][forecastOperation.swiftTier3]),
					[forecastOperation.swiftTier4]: getResultItem(data[clientType.business][RiskLevels.medium]['funding'][forecastOperation.swiftTier4]),
				},
				payment: {
					[forecastOperation.faster]:     getResultItem(data[clientType.business][RiskLevels.medium]['payment'][forecastOperation.faster]),
					[forecastOperation.sepa]:       getResultItem(data[clientType.business][RiskLevels.medium]['payment'][forecastOperation.sepa]),
					[forecastOperation.swiftTier1]: getResultItem(data[clientType.business][RiskLevels.medium]['payment'][forecastOperation.swiftTier1]),
					[forecastOperation.swiftTier2]: getResultItem(data[clientType.business][RiskLevels.medium]['payment'][forecastOperation.swiftTier2]),
					[forecastOperation.swiftTier3]: getResultItem(data[clientType.business][RiskLevels.medium]['payment'][forecastOperation.swiftTier3]),
					[forecastOperation.swiftTier4]: getResultItem(data[clientType.business][RiskLevels.medium]['payment'][forecastOperation.swiftTier4]),
				},
				'balance-EUR':   getServiceResultItem(data[clientType.business][RiskLevels.medium]['balance-EUR']),
				'balance-GBP':   getServiceResultItem(data[clientType.business][RiskLevels.medium]['balance-GBP']),
				'balance-MULTI': getServiceResultItem(data[clientType.business][RiskLevels.medium]['balance-MULTI']),
				opening:         getServiceResultItem(data[clientType.business][RiskLevels.medium].opening),
				subscription:    getServiceResultItem(data[clientType.business][RiskLevels.medium].subscription),
			},
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			[RiskLevels.high]: {
				conversion: {
					[forecastOperation.faster]:            getResultItem(data[clientType.business][RiskLevels.high]['conversion'][forecastOperation.faster]),
					[forecastOperation.sepa]:              getResultItem(data[clientType.business][RiskLevels.high]['conversion'][forecastOperation.sepa]),
					[forecastOperation.virtualConversion]: getResultItem(data[clientType.business][RiskLevels.high]['conversion'][forecastOperation.virtualConversion]),
				},
				funding: {
					[forecastOperation.faster]:     getResultItem(data[clientType.business][RiskLevels.high]['funding'][forecastOperation.faster]),
					[forecastOperation.sepa]:       getResultItem(data[clientType.business][RiskLevels.high]['funding'][forecastOperation.sepa]),
					[forecastOperation.swiftTier1]: getResultItem(data[clientType.business][RiskLevels.high]['funding'][forecastOperation.swiftTier1]),
					[forecastOperation.swiftTier2]: getResultItem(data[clientType.business][RiskLevels.high]['funding'][forecastOperation.swiftTier2]),
					[forecastOperation.swiftTier3]: getResultItem(data[clientType.business][RiskLevels.high]['funding'][forecastOperation.swiftTier3]),
					[forecastOperation.swiftTier4]: getResultItem(data[clientType.business][RiskLevels.high]['funding'][forecastOperation.swiftTier4]),
				},
				payment: {
					[forecastOperation.faster]:     getResultItem(data[clientType.business][RiskLevels.high]['payment'][forecastOperation.faster]),
					[forecastOperation.sepa]:       getResultItem(data[clientType.business][RiskLevels.high]['payment'][forecastOperation.sepa]),
					[forecastOperation.swiftTier1]: getResultItem(data[clientType.business][RiskLevels.high]['payment'][forecastOperation.swiftTier1]),
					[forecastOperation.swiftTier2]: getResultItem(data[clientType.business][RiskLevels.high]['payment'][forecastOperation.swiftTier2]),
					[forecastOperation.swiftTier3]: getResultItem(data[clientType.business][RiskLevels.high]['payment'][forecastOperation.swiftTier3]),
					[forecastOperation.swiftTier4]: getResultItem(data[clientType.business][RiskLevels.high]['payment'][forecastOperation.swiftTier4]),
				},
				'balance-EUR':   getServiceResultItem(data[clientType.business][RiskLevels.high]['balance-EUR']),
				'balance-GBP':   getServiceResultItem(data[clientType.business][RiskLevels.high]['balance-GBP']),
				'balance-MULTI': getServiceResultItem(data[clientType.business][RiskLevels.high]['balance-MULTI']),
				opening:         getServiceResultItem(data[clientType.business][RiskLevels.high].opening),
				subscription:    getServiceResultItem(data[clientType.business][RiskLevels.high].subscription),
			},
		}
	};
}
/* eslint-enable max-len */