export interface IPaginationInfo {
	current_page: number;
	next_page: number | null;
	order: string | null;
	order_asc_desc: "asc" | "desc";
	per_page: number;
	previous_page: number | null;
	total_entries: number;
	total_pages: number;
}

export interface IPagination<T> {
    items: T[];
    info: IPaginationInfo;
}

export const defaultPagination: IPaginationInfo = {
	current_page:   1,
	next_page:      null,
	order:          null,
	order_asc_desc: "asc",
	per_page:       10,
	previous_page:  null,
	total_entries:  0,
	total_pages:    1,
}