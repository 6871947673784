// API
import {apiClient} from "../../clients/api.client";
// HELPERS
import objectToURL from "../../helpers/objectToURL";
import getBrand from "../../helpers/getBrand"
// TYPES
import {IUpdatedManageCompliance} from "../../../components/modules/ManageCompliance/GeneralDetails/index.D"


const {get, put, delete: del, post} = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default ({
	get:     (params?: { [key: string]: string }) => get(`${URL()}/rules?${objectToURL(params)}`),
	getById: (id: string) => get(`${URL()}/rules/${id}`),
	update:  (id: string, data: IUpdatedManageCompliance) => put(`${URL()}/rules/${id}`, data),
	create:  (data: IUpdatedManageCompliance) => post(`${URL()}/rules`, data),
	delete:  (id: string) => del(`${URL()}/rules/${id}`),
});
