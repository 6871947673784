// AXIOS
import {apiClient} from "../../clients/api.client";
// HELPERS
import objectToURL from "../../helpers/objectToURL";
import getBrand from "../../helpers/getBrand";
// TYPES
import {IGetTransfersByCustomer} from "../../store/reducers/transfers/index.D";


const {get} = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default ({
	getTransfer:   (transferId: string) => get(`${URL()}/transfers/${transferId}`),
	findTransfers: (query: string) => get(`${URL()}/transfers?${query}`),
	getTransfers:  (params?: { [k: string]: any; }) => get(
		`${URL()}/transfers/?${objectToURL(params)}`),
	findAllTransfers:       () => get(`${URL()}/transfers`),
	getTransfersByCustomer: ({params, id}: IGetTransfersByCustomer): Promise<string> => get(
		`${URL()}/transfers/customer/${id}?${objectToURL(params)}&per_page=${10}`),
});

