// API
import {apiClient} from "../../clients/api.client";
// HELPERS
import getBrand, {isJigzoBrand} from "../../helpers/getBrand"


const {get} = apiClient;
const URL = () => `organizations/${getBrand(isJigzoBrand())}`;

export default ({
	getPayTiers: (): Promise<string> => get(`${URL()}/payment-tiers`),
});
