// AXIOS
import {apiClient} from "../../clients/api.client";
// HELPERS
import objectToURL from "../../helpers/objectToURL";
import getBrand from "../../helpers/getBrand";
// TYPES
import {IAdminUpdate, IAdminCreate} from "app/store/reducers/admin/index.D";


const {get, patch, delete: del, post} = apiClient;
const URL = () => `/organizations/${getBrand(true)}/admins`;

export default ({
	getAdmin:      (adminId: string) => get(`${URL()}/${adminId}`),
	getAdmins:     (params?: { [k: string]: any; }) => get(`${URL()}/?${objectToURL(params)}`),
	deleteAdmin:   (adminId: string) => del(`${URL()}/${adminId}`),
	createAdmin:   (data: IAdminCreate) => post(`${URL()}`, data),
	updateAdmin:   ({_id, ...params}: Partial<IAdminUpdate>) => patch(`${URL()}/${_id}`, params),
	resetPassword: (adminId: string) => post(`${URL()}/${adminId}/send-set-password`),
	checkEmail:    (email: string) => get(`${URL()}/check-email/${email}`),
	uploadAvatar:  (adminId: string, formData: FormData) => post(`${URL()}/${adminId}/avatar`, formData)
});
