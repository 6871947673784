// HOOKS
import {useAppSelector} from "./redux.hook";
// CONSTANTS
import routes from "../routes/routes";
// HELPERS
import {jigzoPaths} from "../routes/helpers/jigzoPaths";
// HOOKS
import useOperatedOrganization from "../hooks/useOperatedOrganization";
// TYPES
import {IRoutes} from "../routes/routes.D";
import useJigzoAdmin from "./useJigzoAdmin";
import { adminRole } from "../store/reducers/roles/index.D";
import { orgTypes } from "../store/reducers/organizations/index.D";


const useMainRoutes = (): IRoutes[] => {
	const admin = useAppSelector(state => state.admin.adminAuthed);
	const organization = useAppSelector(state => state.organizations.organization);

	const currentOrganization = useOperatedOrganization();
	const isJigzoAdmin = useJigzoAdmin();

	const orgPermissions = admin?.roles?.find(role => role.organization === organization?._id)?.permissions
		|| admin?.roles?.find(role => role.role === adminRole.sysAdmin)?.permissions;

	const jigzoOrg = organization?.type === orgTypes.master;

	const mainRoutes = !orgPermissions || isJigzoAdmin
		? routes.mainRoutes.filter(route => !route.orgTypes
			|| (currentOrganization && route.orgTypes.includes(currentOrganization.type))
			|| (organization && route.orgTypes.includes(organization.type))
		) : routes.mainRoutes.filter(route => !route.permissions.length
			|| route.permissions?.some((key: string) => orgPermissions.includes(key)));


	return jigzoOrg ?
		mainRoutes.filter(route => jigzoPaths.includes(route.path))
		:
		mainRoutes.filter(route => route.path !== "/base-margin-fee");
}

export default useMainRoutes;