// API
import { apiClient } from "../../clients/api.client";
// HELPERS
import getBrand, { isJigzoBrand } from "../../helpers/getBrand";
import objectToURL from "../../helpers/objectToURL";
// TYPES
import { ICreateMarginFeePayload } from "../../store/reducers/marginsFees/index.D";


const { post, get, patch, delete: del } = apiClient;

const URL = () => `organizations/${getBrand(isJigzoBrand())}/margins-fees`;

export default {
  create: (data: ICreateMarginFeePayload) => post(`${URL()}`, data),
  update: (id: string, data: Partial<ICreateMarginFeePayload>) =>
    patch(`${URL()}/${id}`, data),
  delete:                  (id: string) => del(`${URL()}/${id}`),
  readAccountPlatformFees: (params: Record<string, string>) =>
    get(`${URL()}/account?${objectToURL(params)}`),
  readAccountConverionPair: (params: Record<string, string>) =>
    get(`${URL()}/conversion/pair?${objectToURL(params)}`),
  readFiatConversionGlobal: (params: Record<string, string>) =>
    get(`${URL()}/conversion/global?${objectToURL(params)}`),
  readAssetFees: (params: Record<string, string>) =>
    get(`${URL()}/asset?${objectToURL(params)}`),
  readAssetTraidingPairFees: (params: Record<string, string>) =>
    get(`${URL()}/traiding/pair?${objectToURL(params)}`),
  readAssetTraidingGlobalFees: (params: Record<string, string>) =>
    get(`${URL()}/traiding/global?${objectToURL(params)}`),
  readConversionCryptoPair: (params: Record<string, string>) =>
    get(`${URL()}/conversion-crypto/pair?${objectToURL(params)}`),
  readConversionCryptoGlobal: (params: Record<string, string>) =>
    get(`${URL()}/conversion-crypto/global?${objectToURL(params)}`),
  readTransferFees: (params: Record<string, string>) =>
    get(`${URL()}/transfer?${objectToURL(params)}`),
};
