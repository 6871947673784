import {createSlice} from '@reduxjs/toolkit';
// TYPES
import {IRiskAlertSlice} from "./index.D"
// ACTIONS
import asyncActions from './asyncActions';


const initialState = {
	riskAlert: null,
	isLoading: false,
} as IRiskAlertSlice

const riskAlertsSlice = createSlice({
	name:     'riskAlert',
	initialState,
	reducers: {
		clearRiskAlerts(state: IRiskAlertSlice) {
			state.riskAlert = null

			return state;
		}
	},
	extraReducers: {
		[asyncActions.getAlert.fulfilled.type]: (state: IRiskAlertSlice, action) => {
			state.riskAlert = action.payload;
			state.isLoading = false;
		},
		[asyncActions.getAlert.pending.type]: (state: IRiskAlertSlice) => {
			state.isLoading = true;
		},
		[asyncActions.getAlert.rejected.type]: (state: IRiskAlertSlice) => {
			state.isLoading = false;
		},
		[asyncActions.rejectAlert.fulfilled.type]: (state: IRiskAlertSlice, action) => {
			state.riskAlert = action.payload;
			state.isLoading = false;
		},
		[asyncActions.deleteAlert.fulfilled.type]: (state: IRiskAlertSlice, action) => {
			state.riskAlert = action.payload;
			state.isLoading = false;
		},
		[asyncActions.acceptAlert.fulfilled.type]: (state: IRiskAlertSlice, action) => {
			state.riskAlert = action.payload;
			state.isLoading = false;
		},
		[asyncActions.findAlertByCustomer.fulfilled.type]: (state: IRiskAlertSlice, action) => {
			state.riskAlert = action.payload;
			state.isLoading = false;
		},
		[asyncActions.findAlertByBusiness.fulfilled.type]: (state: IRiskAlertSlice, action) => {
			state.riskAlert = action.payload;
			state.isLoading = false;
		},
		[asyncActions.updateAlert.fulfilled.type]: (state: IRiskAlertSlice, action) => {
			state.riskAlert = action.payload;
			state.isLoading = false;
		},
		[asyncActions.findAlertByTransfer.fulfilled.type]: (state: IRiskAlertSlice, action) => {
			state.riskAlert = action.payload;
			state.isLoading = false;
		},
	},
});

export default ({
	...riskAlertsSlice,
	asyncActions
});