// AXIOS
import { apiClient } from '../../clients/api.client';
// HELPERS
import toBase64 from '../../../app/helpers/toBase64';
// TYPES
import { ISetPassword, IChangePasswordPayload, CheckCode } from '../../../app/store/reducers/auth/index.D';
import { WithMethod } from 'app/store/types';


const { get, delete: del, patch, put } = apiClient;
const URL = 'auth';

export default {
	login: (params: any) =>
		get(`${URL}`, {
			headers: {
				Authorization: `Basic ` + toBase64(`${params.username}:${params.password}`),
			},
		}),
	login2fa: (params: Record<string, unknown>) =>
		get(`${URL}/login`, {
			headers: {
				Authorization: `Basic ` + toBase64(`${params.username}:${params.password}`),
			},
		}),
	resendCode:             () => get(`${URL}/login/resend/`),
	set2faMethod:           ({ method }: { method: number }) => get(`${URL}/login/set-2fa/${method}`),
	change2faMethod:        ({ method }: { method: number }) => get(`${URL}/change-2fa/${method}`),
	changePrimary2faMethod: ({ method }: WithMethod) => get(`${URL}/change-primary-2fa/${method}`),
	confirmLogin:           ({ code, method, isPrimary }: CheckCode) =>
		get(`${URL}/login/verify/${method}/${code}`, { params: { isPrimary } }),
	checkCode: ({ method, code, isPrimary = true }: CheckCode) =>
		get(`${URL}/2fa/verify/${method}/${code}`, { params: { isPrimary } }),
	updateSession:         () => put(`${URL}/`, {}),
	updateSessionActivity: (data: number) => put(`${URL}/activity`, { data }),
	getAuthDetails:        () => get(`${URL}/2fa`),
	logout:                () => del(`${URL}/logout`),
	setPassword:           ({ _id, ...params }: ISetPassword) => patch(`${URL}/password/${_id}`, params),
	checkPasswordCode:     (code: string) => get(`${URL}/verify-check/${code}`),
	setPhone:              (data: { phone: string }) => put(`${URL}/phone`, data),
	resetPassword:         (data: { email: string }) => put(`${URL}/password/reset`, data),
	getPasswordVerify:     ({ code }: { code: string }) => get(`${URL}/verify/${code}`),
	confirmPasswordReset:  ({ code, data }: IChangePasswordPayload) => put(`${URL}/password/reset/${code}`, data),
};
