import {createAsyncThunk} from '@reduxjs/toolkit';
// HELPERS
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICES
import riskAlertsService from '../../../services/riskAlerts';
// TYPES
import {IQueriesRiskAlert} from "./index.D"


export default ({
	getRiskAlerts: createAsyncThunk('riskAlerts/get',
		async (params: IQueriesRiskAlert, thunkAPI) => await storeAddErrorNotification(
			() => riskAlertsService.get(params), thunkAPI, false)),
});
