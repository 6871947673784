import {lazy} from "@loadable/component";
import {IRoutes} from "../routes.D";


export default ([
	{
		path:        '/businesses',
		title:       'Businesses',
		component:   lazy(() => import('../../../components/views/Businesses')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['businesses_read', 'businesses_report'],
		routes:      []
	},
	{
		path:        '/businesses/:businessId',
		title:       'Business Details',
		component:   lazy(() => import('../../../components/views/BusinessDetail')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['businesses_read', 'businesses_report'],
		routes:      [
			{
				title:  'Businesses',
				path:   '/businesses',
				params: []
			},
		]
	},
	{
		path:        '/businesses/:businessId/edit',
		title:       'Edit Business',
		component:   lazy(() => import('../../../components/modules/form/BusinessEditForm')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['businesses_update'],
		routes:      [
			{
				title:  'Businesses',
				path:   '/businesses',
				params: []
			},
			{
				title:  'Business Details',
				path:   '/businesses/:businessId',
				params: ['businessId']
			},
		]
	},
] as IRoutes[]);