// AXIOS
import { apiClient } from "../../clients/api.client";
// TYPES
import { IAdmin } from "app/store/reducers/admins/index.D";


const adminService = {
    getMe: () => apiClient.get<IAdmin>('/auth/profile')
};

export default adminService;