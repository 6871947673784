// AXIOS
import { apiClient } from "../../clients/api.client";


const { get } = apiClient;

export default {
  zendeskAuth:     (returnTo: string) => get(`zendesk/admin/auth?returnTo=${returnTo}`),
  zendeskSSOLogin: (params: Record<string, unknown>) =>
    apiClient.get(`/auth/zendesk/admin/login`, params),
  zendeskSSOConfirmLogin: (code: string, query: Record<string, string>) =>
    apiClient.get(`/auth/zendesk/admin/login/verify/${code}?
    brand_id=${query.brand_id}
    &ocale_id=${query.locale_id}
    &return_to=${query.return_to}
    &timestamp=${query.timestamp}`),


};
