import { lazy } from "@loadable/component";
import { IRoutes } from "../routes.D";


export default ([
	{
		path:        '/organizations',
		title:       'Global | Organizations',
		component:   lazy(() => import('../../../components/views/Organizations')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['admin_organizations_read'],
		routes:      []
	},
	{
		path:        '/organizations/create',
		title:       'Create Organization',
		component:   lazy(() => import('../../../components/modules/form/CreateOrganizationForm')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['admin_organizations_create'],
		routes:      []
	},
	{
		path:        '/organizations/:id',
		title:       'Organization details',
		component:   lazy(() => import('../../../components/views/OrganizationDetail')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: [
			'admin_organizations_read',
		],
		routes: [
			{
				title:  'Organizations',
				path:   '/organizations',
				params: []
			},
		]
	},
	{
		path:        '/organizations/:id/edit',
		title:       'Edit Organization',
		component:   lazy(() => import('../../../components/modules/form/OrganizationEditForm')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['admin_organizations_update', 'admin_organizations_delete'],
		routes:      [
			{
				title:  'Organization',
				path:   '/organizations',
				params: []
			},
			{
				title:  'Organization details',
				path:   '/organizations/:id',
				params: ['id']
			},
		]
	},
] as IRoutes[])