import {lazy} from "@loadable/component";
import {IRoutes} from "../routes.D";


export default ([
	{
		path:        '/documents',
		title:       'Documents',
		component:   lazy(() => import('../../../components/views/Documents')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: [],
		routes:      []

	},
	{
		path:        '/documents/:id',
		title:       'Document Details',
		component:   lazy(() => import('../../../components/views/DocumentsDetail')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: [],
		routes:      [
			{
				title:  'Documents',
				path:   '/documents',
				params: []
			},
		]
	},
	{
		path:        '/customers/:customerId/documents/:id',
		title:       'Document Details',
		component:   lazy(() => import('../../../components/views/DocumentsDetail')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ["documents_read", "documents_report"],
		routes:      [
			{
				title:  'Customers',
				path:   '/customers',
				params: []
			},
			{
				title:  'Customers Details',
				path:   '/customers/:customerId',
				params: ['customerId']
			},
			{
				title:  'Documents',
				path:   '/customers/:customerId#documents',
				params: ['customerId']
			},
		]
	},
	{
		path:        '/business/:businessId/documents/:id',
		title:       'Document Details',
		component:   lazy(() => import('../../../components/views/DocumentsDetail')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: [],
		routes:      [
			{
				title:  'Businesses',
				path:   '/businesses',
				params: []
			},
			{
				title:  'Business Details',
				path:   '/businesses/:businessId',
				params: ['businessId']
			},
			{
				title:  'Documents',
				path:   '/businesses/:businessId#documents',
				params: ['businessId']
			},
		]
	},
	{
		path:        '/businesses/:businessId/business-contact/:contactId/documents/:id',
		title:       'Document Details',
		component:   lazy(() => import('../../../components/views/DocumentsDetail')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ["documents_read", "documents_report"],
		routes:      [
			{
				title:  'Businesses',
				path:   '/businesses',
				params: []
			},
			{
				title:  'Business Details',
				path:   '/businesses/:businessId',
				params: ['businessId']
			},
			{
				title:  'Contacts',
				path:   '/businesses/:businessId#contacts',
				params: ['businessId']
			},
			{
				title:  'Contact Details',
				path:   '/businesses/:businessId/business-contact/:contactId',
				params: ['businessId','contactId']
			},
		]
	},
] as IRoutes[]);