import {createSlice} from '@reduxjs/toolkit';
// TYPES
import {IProductSlice} from "./index.D"
// ACTIONS
import asyncActions from './asyncActions';


const initialState = {
	items:     [],
	isLoading: false,
} as IProductSlice

const productSlice = createSlice({
	name:     'products',
	initialState,
	reducers: {
		clearProducts(state: IProductSlice) {
			state.items = []

			return state;
		}
	},
	extraReducers: {
		[asyncActions.getProducts.fulfilled.type]: (state: IProductSlice, action) => {
			state.items = action.payload;
			state.isLoading = false;
		},
	},
});

export default ({
	...productSlice,
	asyncActions
});