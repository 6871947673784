import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { defaultPagination } from '../search/pagination.D';
import asyncActions from './asyncActions';
import {IDocument, IDocumentsSlice} from './index.D';


const initialState = {
  documents: {
    items: [],
    info:  defaultPagination
  },
  document:     null,
  isLoading:    false,
  isLoadingDoc: false,
  error:        '',
  selected:     []
} as IDocumentsSlice

const documentsSlice = createSlice({
  name:     'documents',
  initialState,
  reducers: {
    setSelected: (state: IDocumentsSlice, payload: PayloadAction<Array<IDocument['_id']> | []>) => {
      state.selected = payload.payload;
    },
    clearDocuments(state: IDocumentsSlice) {
      state.documents = {
        items: [],
        info:  {
          current_page:   1,
          next_page:      null,
          order:          null,
          order_asc_desc: "asc",
          per_page:       10,
          previous_page:  null,
          total_entries:  0,
          total_pages:    1,
        }
      }

      return state;
    }
  },
  extraReducers: {
    [asyncActions.getAllDocuments.fulfilled.type]: (state: IDocumentsSlice, action) => {
      state.documents = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.readDocument.fulfilled.type]: (state: IDocumentsSlice, action) => {
      state.document = action.payload;
      state.isLoadingDoc = false;
      state.error = '';
    },
    [asyncActions.getByCustomer.fulfilled.type]: (state: IDocumentsSlice, action) => {
      state.documents.items = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    [asyncActions.getByCustomer.pending.type]: (state: IDocumentsSlice) => {
      state.isLoading = true;
    },
    [asyncActions.readDocument.pending.type]: (state: IDocumentsSlice) => {
      state.isLoadingDoc = true;
    },
    [asyncActions.deleteDocument.fulfilled.type]: (state: IDocumentsSlice, action: PayloadAction<IDocument>) => {
      state.isLoading = false;
    },
    [asyncActions.updateDocument.fulfilled.type]: (state: IDocumentsSlice, action) => {
      state.documents.items = state.documents.items.map(doc=>doc._id === action.payload._id ? action.payload : doc);
      if(state.document) state.document = action.payload
      state.isLoadingDoc = false;
      state.error = '';
    },
  },
});

export default ({
  ...documentsSlice,
  asyncActions,
});
