// @ts-check
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ICreateDefaultNotify} from "../../../helpers/notification";


const notifierSlice = createSlice({
  name:         'notifier',
  initialState: [] as any[],
  reducers:     {
    enqueueNotification: (state, { payload }: PayloadAction<ICreateDefaultNotify>) => {
      state.push({
        key: payload?.options?.key || (new Date().getTime() + Math.random()),
        ...payload,
      });
    },
    closeNotification: (state, {payload}) => state.map((notification) => (
      (!payload || notification.key === payload)
        ? {...notification, dismissed: true}
        : {...notification}
    )),
    removeNotification: (state, {payload}) => state.filter((notification) => (
      notification.key !== payload
    )),
  },
});

export default {
  ...notifierSlice,
};