import dayjs from "dayjs";
import duration from "dayjs/plugin/duration"
import {getSessionTime, removeRefreshToken} from "../localStorage";


dayjs.extend(duration)

export const expiredAuth = () => {
  const sessionTime = getSessionTime() || 0
  if (!sessionTime) return true;

  const isExpired = sessionTime == null || Date.now() > +sessionTime;
  if (isExpired) removeRefreshToken()

  return isExpired;
}