// CORE
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
// ASYNC ACTIONS
import asyncActions from "./asyncActions";
// TYPES
import {IChangeValuePayload, IForecastingServiceFeeData, IForecastingSlice} from "./index.D";
// HELPERS
import getResultData from "../../../../components/modules/Forecast/ForecastingTable/helpers/getResultData";


const initialState = {
	data:    null,
	result:  {},
	loading: false,
} as IForecastingSlice;


const forecastingSlice = createSlice({
	name:     'forecasting',
	initialState,
	reducers: {
		changeValue: (state, {payload}: PayloadAction<IChangeValuePayload>) => {
			const {type, risk, section, operation, valueType, value} = payload;
			const isAdditional = [
				'balance-EUR',
				'balance-GBP',
				'balance-MULTI',
				'opening',
				'subscription'
			].includes(section);

			if(isAdditional){
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const item: IForecastingServiceFeeData = state.result[type][risk][section];
				if(['value', 'percent'].includes(valueType)) {
					item.brand = value;
				} else {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					item[valueType] = value;
					item.amountTotal = item.count * item.amount;
				}
			} else {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				const item = state.result[type][risk][section][operation];
				item[valueType] = value;
				if(['count', 'amount'].includes(valueType)){
					item.totalSum = +(item.count * item.amount).toFixed(2);
				}
				item.result = +((item.totalSum * item.percent / 100) + item.value * item.count).toFixed(2);
				if(item.count <= 0){
					item.result = 0;
				}
			}
		}
	},
	extraReducers: {
		[asyncActions.getForecastData.fulfilled.type]: (state, action) => {
			state.data = action.payload;
			state.result = getResultData(action.payload);
		}
	}
});

export default ({
	...forecastingSlice,
	asyncActions
})