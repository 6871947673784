import {lazy} from "@loadable/component";
import {IRoutes} from "../routes.D";


export default ([
	{
		path:        '/jurisdictions',
		title:       'Jurisdictions',
		component:   lazy(() => import('../../../components/views/Jurisdictions')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['jurisdictions_manage'],
		routes:      []
	}
] as IRoutes[]);