// API
import { apiClient } from "../../clients/api.client";
// HELPERS
import getBrand from "../../helpers/getBrand";
import objectToURL from "../../helpers/objectToURL";
// TYPES
import { ICreateTransactionCostPayload } from "../../store/reducers/transactionsCosts/index.D";


const { post, get, patch, delete: del } = apiClient;

const URL = () => `organizations/${getBrand(true)}/transactions-costs`;

export default {
  create: (data: ICreateTransactionCostPayload) => post(`${URL()}`, data),
  update: (id: string, data: Partial<ICreateTransactionCostPayload>) =>
    patch(`${URL()}/${id}`, data),
  delete:              (id: string) => del(`${URL()}/${id}`),
  readFreeMarketCosts: (params: Record<string, string>) =>
    get(`${URL()}/free-market?${objectToURL(params)}`),
  readKrakenFiat: (params: Record<string, string>) =>
    get(`${URL()}/kraken-fiat?${objectToURL(params)}`),
  readKrakenCrypto: (params: Record<string, string>) =>
    get(`${URL()}/kraken-crypto?${objectToURL(params)}`),
  readKrakenOrders: (params: Record<string, string>) =>
    get(`${URL()}/orders?${objectToURL(params)}`),
};
