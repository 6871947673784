// AXIOS
import getBrand from "../../helpers/getBrand";
import { apiClient } from "../../clients/api.client";
// TYPES
import objectToURL from "../../helpers/objectToURL";


const { get, post } = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default {
  getBatchedTransfers: (params?: Record<string, any>) =>
    get(`${URL()}/bulk-transfers?${objectToURL(params)}`),

  executeBulkTransfer: (params: { transferId: string; refid: string }) =>
    post(`${URL()}/bulk-transfers`, params),

  getBatchedTransfersTransactions: ({transferId, ...params}: Record<string, any>) =>
    get(`${URL()}/bulk-transfers/${transferId}/transactions?${objectToURL(params)}`),

  getBatchedTransfer: ({ transferId }: { transferId: string }) =>
    get(`${URL()}/bulk-transfers/${transferId}`),
};
