// CORE
import {createSlice} from '@reduxjs/toolkit';
import asyncActions from './asyncActions';
// TYPES
import {defaultPagination} from "../search/pagination.D";
import {IClientsState} from "./index.D";


const initialState = {
	loading:    false,
	customers:  {items: [], info: defaultPagination},
	businesses: {items: [], info: defaultPagination},
	uid:        null,
	uidLoading: false,
} as IClientsState;

const clientsSlice = createSlice({
	name:          'clients',
	initialState,
	reducers:      {},
	extraReducers: {
		[asyncActions.search.pending.type]: (state) => {
			state.loading = true;
		},
		[asyncActions.search.rejected.type]: (state) => {
			state.loading = false;
		},
		[asyncActions.search.fulfilled.type]: (state) => {
			state.loading = false;
		},
		[asyncActions.getFirstMatchUid.pending.type]: (state) => {
			state.uidLoading = true;
		},
		[asyncActions.getFirstMatchUid.fulfilled.type]: (state) => {
			state.uidLoading = false
		},
		[asyncActions.getFirstMatchUid.rejected.type]: (state) => {
			state.uidLoading = false;
		}
	}
});

export default ({
	...clientsSlice,
	asyncActions,
});