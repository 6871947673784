// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// HELPERS
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICES
import payTierService from '../../../services/payTiers'


export default ({
	getPayTiers: createAsyncThunk('get/paymentTiers',
		async (params, thunkAPI) => await storeAddErrorNotification(
			() => payTierService.getPayTiers(), thunkAPI, false)),
});
