import {createSlice} from '@reduxjs/toolkit';
// TYPES
import {IManageComplianceDetailsSlice} from "./index.D"
// ACTIONS
import asyncActions from './asyncActions';


const initialState = {
	rule:      null,
	isLoading: false,
} as IManageComplianceDetailsSlice

const riskAlertsSlice = createSlice({
	name:     'manageCompliance',
	initialState,
	reducers: {
		clearRules(state:IManageComplianceDetailsSlice){
			state.rule = null;

			return state;
		}
	},
	extraReducers: {
		[asyncActions.getRuleById.fulfilled.type]: (state: IManageComplianceDetailsSlice, action) => {
			state.rule = action.payload;
			state.isLoading = false;
		},
		[asyncActions.getRuleById.pending.type]: (state: IManageComplianceDetailsSlice) => {
			state.isLoading = true;
		},
		[asyncActions.getRuleById.rejected.type]: (state: IManageComplianceDetailsSlice) => {
			state.isLoading = false;
		},
		[asyncActions.updateRule.fulfilled.type]: (state: IManageComplianceDetailsSlice, action) => {
			state.rule = action.payload;
			state.isLoading = false;
		},
		[asyncActions.updateRule.pending.type]: (state: IManageComplianceDetailsSlice) => {
			state.isLoading = true;
		},
		[asyncActions.updateRule.rejected.type]: (state: IManageComplianceDetailsSlice) => {
			state.isLoading = false;
		},
	},
});

export default ({
	...riskAlertsSlice,
	asyncActions
});