import {createAsyncThunk} from '@reduxjs/toolkit';
// HELPERS
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICES
import balanceFeesService from '../../../services/balanceFees';
// TYPES
import {IFeeBalanceCreate, IQueriesBalanceFees} from "./index.D";


export default ({
	getBalanceFees: createAsyncThunk('balanceFees/get',
		async (params:IQueriesBalanceFees, thunkAPI) => await storeAddErrorNotification(
			() => balanceFeesService.getBalanceFees(params), thunkAPI, false)),
	createBalanceFees: createAsyncThunk('balanceFees/get',
		async (data: IFeeBalanceCreate, thunkAPI) => await storeAddErrorNotification(
			() => balanceFeesService.createBalanceFee(data), thunkAPI, false)),
	updateBalanceFees: createAsyncThunk('balanceFees/get',
		async (params: {id: string, data: Partial<IFeeBalanceCreate>}, thunkAPI) => await storeAddErrorNotification(
			() => balanceFeesService.updateBalanceFee(params), thunkAPI, false)),
	deleteBalanceFees: createAsyncThunk('balanceFees/get',
		async (id: string, thunkAPI) => await storeAddErrorNotification(
			() => balanceFeesService.deleteBalanceFee(id), thunkAPI, false)),
})