// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// HELPERS
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICES
import productService from '../../../services/products'


export default ({
	getProducts: createAsyncThunk('get/products',
		async (params, thunkAPI) => await storeAddErrorNotification(
			() => productService.getProducts(), thunkAPI, false)),
});
