import {createAsyncThunk} from '@reduxjs/toolkit';
// HELPERS
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICES
import regularFeesService from '../../../services/regularFee';
// TYPES
import {IParams} from "./index.D";


export default ({
	getCustomerFees: createAsyncThunk('regularFees/getCustomerFees',
		async ({id, params}: {id: string, params: IParams}, thunkAPI) => await storeAddErrorNotification(
			() => regularFeesService.getCustomerFees(id, params), thunkAPI
		))
})