// core
import { createSlice } from "@reduxjs/toolkit";

// async actions
import asyncActions from "./asyncActions";
// helpers
import { defaultPagination } from "../search/pagination.D";


const initialState = {
  batchedTransfers: {
    items: [],
    info:  defaultPagination,
  },
  isLoading: false,
  error:     '',
};


const batchedTransfersSlice = createSlice({
  name:          'batchedTransfers',
  initialState,
  reducers:      {},
  extraReducers: {
    [asyncActions.getBatchedTransfers.fulfilled.type]: (
      state,
      { payload }
    ) => {
      state.batchedTransfers = payload;
      state.isLoading = false;
      state.error = "";
    },
    [asyncActions.getBatchedTransfers.pending.type]: (state) => {
      state.isLoading = true;
      state.error = "";
    },
    [asyncActions.getBatchedTransfers.rejected.type]: (
      state,
      { payload }
    ) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export default({
    ...batchedTransfersSlice,
    asyncActions,
})
