// API
import {apiClient} from "../../clients/api.client";
// HELPERS
import getBrand, {isJigzoBrand} from "../../helpers/getBrand"
import objectToURL from "../../helpers/objectToURL";
// TYPES
import {
	ICreateGlobalMargin,
	IQueriesMargins,
	ICreateCurrencyPair,
	IUpdateGlobalMargin,
	IMarginCurrencyPair,
	IUpdateGlobalMargins
} from "../../store/reducers/margins/index.D";


const {get, post, patch, delete: del, put} = apiClient;
const URL = () => `organizations/${getBrand(isJigzoBrand())}`;

export default ({
	getGlobalMargin:     () => get(`${URL()}/margin-rates`),
	createGlobalMargin:  (data: ICreateGlobalMargin) => post(`${URL()}/margin-rates`, data),
	updateGlobalMargin:  ({id, data}: IUpdateGlobalMargin) => put(`${URL()}/margin-rates/${id}`, data),
	updateGlobalMargins: (data: IUpdateGlobalMargins) => put(`${URL()}/margin-rates`, data),
	getCurrencyPairs:    (params: IQueriesMargins) => get(
		`${URL()}/margin-rates/currency-pairs?${objectToURL(params)}`
	),
	deleteCurrencyPairs: (id: string) => del(`${URL()}/margin-rates/currency-pair/${id}`),
	createCurrencyPair:  (data: ICreateCurrencyPair) => post(`${URL()}/margin-rates/currency-pair`, data),
	updateCurrencyPair:  (id: string, data: Partial<IMarginCurrencyPair>) => patch(
		`${URL()}/margin-rates/currency-pair/${id}`, data),
	getBaseMargin:    () => get(`${URL()}/margin-rates/bases`),
	createBaseMargin: (data: ICreateGlobalMargin) => post(`${URL()}/margin-rates/bases`, data),
	updateBaseMargin: (id: string, percent: ICreateGlobalMargin) => put(
		`${URL()}/margin-rates/bases/${id}`, percent
	),
});
