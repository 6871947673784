import {createAsyncThunk} from '@reduxjs/toolkit';
// HELPERS
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICES
import noteService from '../../../services/notes';
// TYPES
import {INoteForm} from '../../../../components/modules/form/RislAlertNoteForm/index.D'


export default ({
	findNotesByModel: createAsyncThunk('notes/findByModel',
		async (id: string, thunkAPI) => await storeAddErrorNotification(
			() => noteService.findNotesByModel(id), thunkAPI, false)),
	createNote: createAsyncThunk('notes/create',
		async (data: INoteForm, thunkAPI) => await storeAddErrorNotification(
			() => noteService.createNote(data), thunkAPI, false)),
});
