// AXIOS
import {apiClient} from "../../clients/api.client";
// HELPERS
import objectToURL from "../../helpers/objectToURL";
import getBrand from "../../helpers/getBrand";


const {get} = apiClient;

const URL = () => `organizations/${getBrand()}/wait-list`;

export default ({
	getWaitList: (params: Record<string, unknown>) => get(`${URL()}?${objectToURL(params)}`),
})