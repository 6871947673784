import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import asyncActions from "./asyncActions";
import { ISpVerificationSlice, ISPVerification } from "./index.D";


const initialState = {
  verification:         null,
  isLoading:            false,
  error:                "",
  businessVerification: null,
} as ISpVerificationSlice;

const verificationSlice = createSlice({
  name:          "spVerification",
  initialState,
  reducers:      {},
  extraReducers: {
    // CUSTOMER
    [asyncActions.getSpVerification.fulfilled.type]: (
      state: ISpVerificationSlice,
      action: PayloadAction<ISPVerification>
    ) => {
      state.verification = action.payload;
      state.isLoading = false;
      state.error = "";
    },
    [asyncActions.getSpVerification.pending.type]: (
      state: ISpVerificationSlice
    ) => {
      state.isLoading = true;
      state.error = "";
    },
    [asyncActions.getSpVerification.rejected.type]: (
      state: ISpVerificationSlice,
      action: PayloadAction<any>
    ) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    // BUSINESS
    [asyncActions.getSpVerificationBusiness.pending.type]: (state) => {
      state.isLoading = true;
    },
    [asyncActions.getSpVerificationBusiness.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [asyncActions.getSpVerificationBusiness.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.businessVerification = action.payload;
    },
  },
});

export default {
  ...verificationSlice,
  asyncActions,
};
