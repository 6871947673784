// CORE
import { createAsyncThunk } from "@reduxjs/toolkit";
// HELPERS
import { storeAddErrorNotification } from "../../../helpers/errors";
// SERVICE
import settingsService from "../../../services/settings";
import { ICreateSettingPayload } from "./index.D";


export default {
  createSetting: createAsyncThunk(
    "settings/create",
    async (data: ICreateSettingPayload, thunkAPI) =>
      await storeAddErrorNotification(
        () => settingsService.createSetting(data),
        thunkAPI
      )
  ),
  getSettings: createAsyncThunk(
    "settings/read",
    async (params: Record<string, string | number>, thunkAPI) =>
      await storeAddErrorNotification(
        () => settingsService.getSettings(params),
        thunkAPI
      )
  ),
  updateSetting: createAsyncThunk(
    "settings/update",
    async (
      data: { id: string; data: Partial<ICreateSettingPayload> },
      thunkAPI
    ) =>
      await storeAddErrorNotification(
        () => settingsService.updateSetting(data),
        thunkAPI
      )
  ),
  deleteSetting: createAsyncThunk(
    "settings/delete",
    async (data: { id: string }, thunkAPI) =>
      await storeAddErrorNotification(
        () => settingsService.deleteSetting(data),
        thunkAPI
      )
  ),
};
