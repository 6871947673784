import {lazy} from "@loadable/component";
import {IRoutes} from "../routes.D";


export default ([
	{
		path:        '/registration-documents',
		title:       'Registration Documents',
		component:   lazy(() => import('../../../components/views/ManageRiskLevels')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['documents_read'],
		routes:      []
	},
] as IRoutes[]);