// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICE
import registrationRequestsService from "../../../services/registrationRequests";


export default ({
	get: createAsyncThunk('registrationRequests/get',
		async (params: Record<string, string | number>, thunkAPI) => await storeAddErrorNotification(
			() => registrationRequestsService.getRegistrationRequests(params), thunkAPI, false)),
	getById: createAsyncThunk('registrationRequests/getById',
		async (id: string, thunkAPI) => await storeAddErrorNotification(
			() => registrationRequestsService.getRegistrationRequestById(id), thunkAPI, false)),

	requestPayment: createAsyncThunk('registrationRequests/requestPayment',
		async (data: {id: string, link: string}, thunkAPI) => await storeAddErrorNotification(
			() => registrationRequestsService.requestPayment(data.id, data.link), thunkAPI, false)),
	reject: createAsyncThunk('registrationRequests/reject',
		async (id: string, thunkAPI) => await storeAddErrorNotification(
			() => registrationRequestsService.reject(id), thunkAPI, false)),
	continue: createAsyncThunk('registrationRequests/continue',
		async (id: string, thunkAPI) => await storeAddErrorNotification(
			() => registrationRequestsService.continue(id), thunkAPI, false)),
});