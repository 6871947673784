// AXIOS
import {apiClient} from "../../clients/api.client";
// HELPERS
import getBrand from "../../helpers/getBrand";
import objectToURL from "../../helpers/objectToURL";
// TYPES
import {IGetBeneficiaries} from "../../store/reducers/beneficiaries/index.D";


const {get} = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default ({
	getBeneficiaries:           (): Promise<string> => get(`${URL()}/beneficiaries`),
	getBeneficiariesByCustomer: ({id, params}: IGetBeneficiaries): Promise<string> => get(
		`${URL()}/customers/${id}/beneficiaries?${objectToURL(params)}`),
});
