// @ts-check
import messageNotify from '../notification/notificationMessage';

/**
 * @param {{message: string, response}} error
 * @return {{ notificationMessage: string, unauthorized: boolean }}
 */
export default (error: any) => {
  const {message, response} = error;
  const {status, data = {}} = response || {};
  let notificationMessage = data?.message || message;
  let unauthorized = false;
  if (typeof notificationMessage !== 'string') {
    notificationMessage = messageNotify.serverError(status);
  }
  if (data?.action === 'auth-token' && (status === 401 || status === 403)) {
    notificationMessage = messageNotify.unauthorized;
    unauthorized = true;
  } else if (status === 400) {
    notificationMessage = notificationMessage || messageNotify.badRequest;
  }

  return {notificationMessage, unauthorized, status};
};