// API
import { apiClient } from "../../clients/api.client";
// HELPERS
import getBrand from "../../helpers/getBrand";
// TYPES
import { IUpdateRiskTolerance } from "../../store/reducers/riskToleranceSettings/index.D";


const { get, put } = apiClient;

const URL = () => `organizations/${getBrand()}/risk-tolerance`;

export default {
  getRiskToleranceSettings:    () => get(URL()),
  updateRiskToleranceSettings: (data: IUpdateRiskTolerance) => put(URL(), data),
};
