// AXIOS
import {apiClient} from "../../clients/api.client";
// HELPERS
import objectToURL from "../../helpers/objectToURL";
import getBrand from "../../helpers/getBrand"
// TYPES


const {get} = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default ({
	getTransactions: (params?: { [k: string]: any; }) => get(
		`${URL()}/transactions/find?${objectToURL(params)}`),
	getTransfer: (params?: { [k: string]: any; }) => get(
		`${URL()}/transactions/?${objectToURL(params)}&per_page=${10}`),
	getTransactionsByTransfer: ({id}:{id:string}) => get(
		`${URL()}/transfers/${id}/transactions`),
	getTransactionsCryptoByTransfer: ({id}:{id:string}) => get(
		`${URL()}/transfers/${id}/transactions-crypto`),
	getTransactionsCryptoByCustomer: ({id, params}:{id:string, params: Record<string, unknown>}) => get(
		`${URL()}/transactions/crypto/customer/${id}?${objectToURL(params)}`),
});

