// API
import {apiClient} from "../../clients/api.client";
// HELPERS
import objectToURL from "../../helpers/objectToURL";
import getBrand from "../../helpers/getBrand"
// TYPES
import {IQueriesCountries} from "../../store/reducers/countries/index.D"


const {get} = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default ({
	get:     (params: IQueriesCountries) => get(`${URL()}/countries?${objectToURL(params)}`),
	findAll: () => get('helper/countries'),
});
