import React, {ReactElement} from "react";
import {Dialog, Typography, Box} from "@mui/material";
import {DialogsProps} from './index.D';
import useStyles from "./styles";
import {Close} from "../../../app/icons";


const BasicDialogs = ({open, onClose, label, children, className = ''}: DialogsProps): ReactElement => {
	const {typoHeader, dialogWrapper, headerWrapper, closeWrapper,containerWrapper} = useStyles();

	return (
		<Dialog className={`${containerWrapper} ${className}`} onClose={onClose} open={open}>
			<Box className={dialogWrapper}>
				<Box className={headerWrapper}>
					<Typography className={typoHeader} variant={'h5'}>
						<strong>{label}</strong>
					</Typography>
					<span onClick={onClose} className={closeWrapper}>
						<Close/>
					</span>
				</Box>
				{children}
			</Box>
		</Dialog>
	)
}

export default BasicDialogs;