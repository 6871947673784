import {createAsyncThunk} from '@reduxjs/toolkit';
import {storeAddErrorNotification} from '../../../helpers/errors';
import reportsService from '../../../../app/services/reports'


export default ({
	getForecastData: createAsyncThunk('forecast/get',
		async (params, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.getForecastData(), thunkAPI, false)),
	saveForecastConfig: createAsyncThunk('forecast/save',
		async (data: Record<string, unknown>, thunkAPI) => await storeAddErrorNotification(
			() => reportsService.saveForecastConfig(data), thunkAPI, false)),
});