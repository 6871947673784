import {lazy} from "@loadable/component";
import {IRoutes} from "../routes.D";


export default ([
	{
		path:        '/transfers',
		title:       'Transfers',
		component:   lazy(() => import('../../../components/views/Transfer')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['transfer_read', 'transfers_report'],
		routes:      []
	},
	{
		path:        '/transfers/:id',
		title:       'Transfer details',
		component:   lazy(() => import('../../../components/views/TransfersDetail')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['transfer_read', 'transfers_report'],
		routes:      [
			{
				path:   '/transfers',
				title:  'Transfers',
				params: []
			},
		]
	},
	{
		path:        '/customers/:customerId/transfers/:id',
		title:       'Transfer Details',
		component:   lazy(() => import('../../../components/views/TransfersDetail')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['transfer_read', 'transfers_report'],
		routes:      [
			{
				title:  'Customers',
				path:   '/customers',
				params: []
			},
			{
				title:  'Customers Details',
				path:   '/customers/:customerId',
				params: ['customerId']
			},
			{
				title:  'Transfers',
				path:   '/customers/:customerId#transfers',
				params: ['customerId']
			},
		]
	},
	{
		path:        '/businesses/:businessId/transfers/:id',
		title:       'Transfer Details',
		component:   lazy(() => import('../../../components/views/TransfersDetail')),
		layout:      lazy(() => import('../../../components/layouts/MainLayout')),
		exact:       true,
		private:     true,
		permissions: ['transfer_read', 'transfers_report'],
		routes:      [
			{
				title:  'Businesses',
				path:   '/businesses',
				params: []
			},
			{
				title:  'Business Details',
				path:   '/businesses/:businessId',
				params: ['businessId']
			},
			{
				title:  'Transfers',
				path:   '/businesses/:businessId#transfers',
				params: ['businessId']
			},
		]
	},
] as IRoutes[]);