import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import asyncActions from './asyncActions';
import { ICustomerSlice, ICustomer } from "./index.D";


const initialState = {
    customer:  {},
    isLoading: false,
    error:     ''
} as ICustomerSlice

const customerSlice = createSlice({
    name:          'customer',
    initialState,
    reducers:      {},
    extraReducers: {
        [asyncActions.getCustomer.fulfilled.type]: (state: ICustomerSlice, action: PayloadAction<ICustomer>) => {
            state.customer = action.payload;
            state.isLoading = false;
            state.error = '';
        },
        [asyncActions.getCustomer.rejected.type]: (state: ICustomerSlice, action: PayloadAction<ICustomer>) => {
            state.isLoading = false;
            state.error = action.payload;
        },
        [asyncActions.getAvatar.fulfilled.type]: (state: ICustomerSlice, action: PayloadAction<Blob>) => {
            if(action.payload?.size) {
                state.avatar = URL.createObjectURL(action.payload);
            } else {
                state.avatar = null;
            }
            state.isLoading = false;
            state.error = '';
        },
    },
});

export default ({
    ...customerSlice,
    asyncActions,
});