import {createAsyncThunk} from '@reduxjs/toolkit';
import {storeAddErrorNotification} from '../../../helpers/errors';
import servicesRoles from '../../../services/roles'
import {IRoleCredentials} from 'components/modules/UserPermissionsSelect/index.D';


export default ({
	getRoles: createAsyncThunk('roles',
		async (params, thunkAPI) => await storeAddErrorNotification(
			() => servicesRoles.getRoles(), thunkAPI, false)),
	getRolesByOrganization: createAsyncThunk('roles',
		async (id: string, thunkAPI) => await storeAddErrorNotification(
			() => servicesRoles.getRolesByOrganization(id), thunkAPI, false)),
	getAvailableRoles: createAsyncThunk('availableRoles',
		async (id: string, thunkAPI) => await storeAddErrorNotification(
			() => servicesRoles.getAvailableRoles(id), thunkAPI, false)),
	getPermissions: createAsyncThunk('permissions',
		async (params, thunkAPI) => await storeAddErrorNotification(
			() => servicesRoles.getPermissions(), thunkAPI, false)),
	getRole: createAsyncThunk('role',
		async (params: string, thunkAPI) => await storeAddErrorNotification(
			() => servicesRoles.getRole(params), thunkAPI, false)),
	updateRole: createAsyncThunk('updateRole',
		async (params: Partial<IRoleCredentials>, thunkAPI) => await storeAddErrorNotification(
			() => servicesRoles.updateRole(params), thunkAPI, false)),
});
