import {RiskLevels} from "../customer/index.D";


export enum clientType {
	customer = 1,
	business = 2,
}

export enum forecastOperation {
	sepa,
	faster,
	swift,
	virtualConversion, // JIGZO
	local,             // CC currencies excluding EUR & GBP
	swiftSHA,
	swiftTier1,
	swiftTier2,
	swiftTier3,
	swiftTier4,
}

export interface IForecastingFeeData {
	value: number;
	percent: number;
}

export interface IForecastingRawData {
	type: forecastOperation;
	currency: string;
	averageSum: number;
	count: number;
	base: IForecastingFeeData;
	brand: IForecastingFeeData;
}

export interface IForecastingServiceFeeData {
	count: number;
	amount: number;
	amountTotal: number;
	brand: number;
	base: number;
}

export interface IForecastingItem {
	type: clientType;
	risk: RiskLevels;

	funding: IForecastingRawData[];
	conversion: IForecastingRawData[];
	payment: IForecastingRawData[];
}

export interface IForecastingSimple {
	currency: string;
	averageSum: number;
	count: number;
	base: IForecastingFeeData;
	brand: IForecastingFeeData;
}
export interface IForecastItem<T = IForecastingSimple> {
	funding: {
		[forecastOperation.faster]: T;
		[forecastOperation.sepa]: T;
		[forecastOperation.swiftTier1]: T;
		[forecastOperation.swiftTier2]: T;
		[forecastOperation.swiftTier3]: T;
		[forecastOperation.swiftTier4]: T;
	};
	conversion: {
		[forecastOperation.faster]: T;
		[forecastOperation.sepa]: T;
		[forecastOperation.virtualConversion]: T;
	};
	payment: {
		[forecastOperation.faster]: T;
		[forecastOperation.sepa]: T;
		[forecastOperation.swiftTier1]: T;
		[forecastOperation.swiftTier2]: T;
		[forecastOperation.swiftTier3]: T;
		[forecastOperation.swiftTier4]: T;
	};
	'balance-EUR': IForecastingServiceFeeData;
	'balance-GBP': IForecastingServiceFeeData;
	'balance-MULTI': IForecastingServiceFeeData;
	subscription: IForecastingServiceFeeData;
	opening: IForecastingServiceFeeData;
}

export type IForecastRiskData<T = IForecastingSimple> = {
	[key in Exclude<RiskLevels, RiskLevels.NA>]: IForecastItem<T>;
}

export type IForecastData = {
	[key in clientType]: IForecastRiskData;
}

export interface IForecastResultItem {
	amount: number;
	count: number;
	totalSum: number;
	value: number;
	percent: number;
	result: number;
}

export interface IForecastSaveItem {
	type: number;
	risk: number;
	section: string;
	operation: number;
	percent: number;
	value: number;
	count: number;
	amount: number;
}

export interface IForecastSaveData {
	data: IForecastSaveItem[];
}

export type IForecastResultData = {
	[key in clientType]: IForecastRiskData<IForecastResultItem>;
}

export interface IForecastingSlice {
	data: IForecastData | null;
	result: IForecastResultData;
	loading: boolean;
}

export interface IChangeValuePayload {
	type: clientType;
	risk: Exclude<RiskLevels, RiskLevels.NA>;
	section: 'conversion'|'funding'|'payment'|'balance-EUR'|'balance-GBP'|'balance-MULTI'|'opening'|'subscription';
	operation: forecastOperation;
	valueType: 'value' | 'percent' | 'amount' | 'count';
	value: number;
}

export interface IChangeValuePayloadPartial {
	section: 'conversion'|'funding'|'payment'|'balance-EUR'|'balance-GBP'|'balance-MULTI'|'opening'|'subscription';
	operation: forecastOperation;
	valueType: 'value' | 'percent' | 'amount' | 'count';
	value: number;
}
