// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// HELPERS
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICES
import cryptoFeesMarginsService from '../../../services/cryptoFeesMargins';
// TYPES
import {ICryptoMarginFeeCreateData, ICryptoRegularFeeCreateData} from './index.D';


export default ({
	getPlatformFees: createAsyncThunk('cryptoFeesMargins/getPlatformFees',
		async (params: Record<string, unknown>, thunkAPI)=> await storeAddErrorNotification(
			() => cryptoFeesMarginsService.getPlatformFees(params), thunkAPI, false)),
	createPlatformFee: createAsyncThunk('cryptoFeesMargins/createPlatformFee',
		async (data: ICryptoMarginFeeCreateData, thunkAPI)=> await storeAddErrorNotification(
			() => cryptoFeesMarginsService.createPlatformFee(data), thunkAPI, false)),

	getConversionGlobal: createAsyncThunk('cryptoFeesMargins/getConversionGlobal',
		async (params: Record<string, unknown>, thunkAPI)=> await storeAddErrorNotification(
			() => cryptoFeesMarginsService.getConversionGlobal(params), thunkAPI, false)),
	createConversionGlobal: createAsyncThunk('cryptoFeesMargins/createConversionGlobal',
		async (data: ICryptoMarginFeeCreateData, thunkAPI)=> await storeAddErrorNotification(
			() => cryptoFeesMarginsService.createConversionGlobal(data), thunkAPI, false)),

	getConversionPairs: createAsyncThunk('cryptoFeesMargins/getConversionPairs',
		async (params: Record<string, unknown>, thunkAPI)=> await storeAddErrorNotification(
			() => cryptoFeesMarginsService.getConversionPairs(params), thunkAPI, false)),
	createConversionPair: createAsyncThunk('cryptoFeesMargins/createConversionPair',
		async (data: ICryptoMarginFeeCreateData, thunkAPI)=> await storeAddErrorNotification(
			() => cryptoFeesMarginsService.createConversionPair(data), thunkAPI, false)),

	updateMarginFee: createAsyncThunk('cryptoFeesMargins/updateMarginFee',
		async (data: {data: ICryptoMarginFeeCreateData, id: string}, thunkAPI)=> await storeAddErrorNotification(
			() => cryptoFeesMarginsService.updateMarginFee(data), thunkAPI, false)),
	deleteMarginFee: createAsyncThunk('cryptoFeesMargins/deleteMarginFee',
		async (id: string, thunkAPI)=> await storeAddErrorNotification(
			() => cryptoFeesMarginsService.deleteMarginFee(id), thunkAPI, false)),

	getBalanceFees: createAsyncThunk('cryptoFeesMargins/getBalanceFees',
		async (params: Record<string, unknown>, thunkAPI)=> await storeAddErrorNotification(
			() => cryptoFeesMarginsService.getBalanceFees(params), thunkAPI, false)),
	getMaintenanceFees: createAsyncThunk('cryptoFeesMargins/getMaintenanceFees',
		async (params: Record<string, unknown>, thunkAPI)=> await storeAddErrorNotification(
			() => cryptoFeesMarginsService.getMaintenanceFees(params), thunkAPI, false)),
	createRegularFee: createAsyncThunk('cryptoFeesMargins/createRegularFee',
		async (data: ICryptoRegularFeeCreateData, thunkAPI)=> await storeAddErrorNotification(
			() => cryptoFeesMarginsService.createRegularFee(data), thunkAPI, false)),
	updateRegularFee: createAsyncThunk('cryptoFeesMargins/updateRegularFee',
		async (data: {data: ICryptoRegularFeeCreateData, id: string}, thunkAPI)=> await storeAddErrorNotification(
			() => cryptoFeesMarginsService.updateRegularFee(data), thunkAPI, false)),
	deleteRegularFee: createAsyncThunk('cryptoFeesMargins/deleteRegularFee',
		async (id: string, thunkAPI)=> await storeAddErrorNotification(
			() => cryptoFeesMarginsService.deleteRegularFee(id), thunkAPI, false)),
});