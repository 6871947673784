import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
// HELPERS
import { storeAddErrorNotification } from '../../../helpers/errors';
// SERVICES
import organizationService from "../../../services/organizations"
// TYPES
import { IOrganization, IOrganizationCreate, IQueriesOrganization } from './index.D'


export default ({
	getOrganizations: createAsyncThunk('organizationList/get',
		async (id: string, thunkAPI) => await storeAddErrorNotification(
			() => organizationService.getOrganizations(id), thunkAPI, false)),
	getOrganizationsByRole: createAsyncThunk('organizationByRole/get',
		async (params: { role: string, id: string }, thunkAPI) => await storeAddErrorNotification(
			() => organizationService.getOrganizationsByRole(params), thunkAPI, false)),
	getAllOrganizations: createAsyncThunk('allOrganizations/get',
		async (_, thunkAPI) => await storeAddErrorNotification(
			() => organizationService.getAllOrganizations(), thunkAPI, false)),
	getOrganizationsByAdmin: createAsyncThunk('organizationsByAdmin/get',
		async (data: IQueriesOrganization, thunkAPI) => await storeAddErrorNotification(
			() => organizationService.getOrganizationsByAdmin(data), thunkAPI, false)),
	getOrganization: createAsyncThunk('organization/get',
		async (id: string, thunkAPI) => await storeAddErrorNotification(
			() => organizationService.getOrganization(id), thunkAPI, false)),
	createOrganization: createAsyncThunk<AxiosResponse<IOrganization>, IOrganizationCreate>('organization/create',
		async (data, thunkAPI) => await storeAddErrorNotification(
			() => organizationService.createOrganization(data), thunkAPI, false)),
	updateOrganization: createAsyncThunk<AxiosResponse<IOrganization>, IOrganization>('organization/update',
		async (data, thunkAPI) => await storeAddErrorNotification(
			() => organizationService.updateOrganization(data), thunkAPI, false)),
	deleteOrganization: createAsyncThunk<AxiosResponse<IOrganization>, string>('organization/delete',
		async (id, thunkAPI) => await storeAddErrorNotification(
			() => organizationService.deleteOrganization(id), thunkAPI, false)),
	deployOrganization: createAsyncThunk<AxiosResponse<IOrganization>, string>('organization/deploy',
		async (id, thunkAPI) => await storeAddErrorNotification(
			() => organizationService.deployOrganization(id), thunkAPI, false)),
});