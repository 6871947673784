// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// HELPERS
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICES
import externalAssetsService from '../../../services/externalAssets';


export default ({
	getExternalAssets: createAsyncThunk('externalAssets/getExternalAssets',
		async (customerID: string, thunkAPI)=> await storeAddErrorNotification(
			() => externalAssetsService.getExternalAssets(customerID), thunkAPI, false)),
});