// CORE
import {createSlice} from '@reduxjs/toolkit';
// ASYNC ACTIONS
import asyncActions from "./asyncActions";
// TYPES
import {IJurisdictionSlice} from "./types";


const initialState = {
	loading:  false,
	fetching: false,
	list:     [],
} as IJurisdictionSlice;


const jurisdictionsSlice = createSlice({
	name:     'jurisdictions',
	initialState,
	reducers: {
		clearState: (state) => {
			state.loading = false;
			state.fetching = false;
			state.list = [];
		}
	},
	extraReducers: {
		// GET
		[asyncActions.get.pending.type]: (state) => {
			state.fetching = true;
			state.list = [];
		},
		[asyncActions.get.rejected.type]: (state) => {
			state.fetching = false;
		},
		[asyncActions.get.fulfilled.type]: (state, action) => {
			state.fetching = false;
			state.list = action.payload;
		},
		// CREATE
		[asyncActions.create.pending.type]: (state) => {
			state.loading = true;
		},
		[asyncActions.create.rejected.type]: (state) => {
			state.loading = false;
		},
		[asyncActions.create.fulfilled.type]: (state, action) => {
			state.loading = false;
			state.list.push(action.payload);
		},
		// CREATE MANY
		[asyncActions.bulkCreate.pending.type]: (state) => {
			state.loading = true;
		},
		[asyncActions.bulkCreate.rejected.type]: (state) => {
			state.loading = false;
		},
		[asyncActions.bulkCreate.fulfilled.type]: (state, action) => {
			state.loading = false;
			state.list = [...state.list, ...action.payload];
		},
		// DELETE
		[asyncActions.delete.pending.type]: (state) => {
			state.loading = true;
		},
		[asyncActions.delete.rejected.type]: (state) => {
			state.loading = false;
		},
		[asyncActions.delete.fulfilled.type]: (state, action) => {
			state.loading = false;
			state.list = state.list.filter((c) => c._id !== action.payload);
		},
		// DELETE MANY
		[asyncActions.bulkDelete.pending.type]: (state) => {
			state.loading = true;
		},
		[asyncActions.bulkDelete.rejected.type]: (state) => {
			state.loading = false;
		},
		[asyncActions.bulkDelete.fulfilled.type]: (state, action) => {
			state.loading = false;
			state.list = state.list.filter((c) => !action.payload.includes(c._id));
		},
	},
});

export default ({
	...jurisdictionsSlice,
	asyncActions
});