export enum clientType {
	customer = 1,
	business = 2,
}

export enum forecastDaxOperation {
	sepaReceive,
	fasterReceive,

	sepaSend,
	fasterSend,

	eurToCrypto,
	gbpToCrypto,
	cryptoToEur,
	cryptoToGbp,

	balanceEur,
	balanceGbp,

	subscriptionEur,
	subscriptionGbp,

	opening,

	// SWIFT, CHAPS, TARGET ADDED
	swiftReceive,
	chapsReceive,
	targetReceive,

	swiftSend,
	chapsSend,
	targetSend,
}

export interface IForecastingDaxFeeData {
	value: number;
	percent: number;
}

export interface IForecastingDaxRawData {
	type: clientType;
	operation: forecastDaxOperation;
	currency: string;
	averageSum: number;
	totalSum: number;
	count: number;
	result: number;
	base: IForecastingDaxFeeData;
	brand: IForecastingDaxFeeData;
}

export interface IForecastingDaxSlice {
	data: IForecastingDaxRawData[];
	result: IForecastingDaxRawData[];
	editable: IForecastingDaxRawData[];
	currentTotal: number;
	loading: boolean;
}

export interface IChangePayload {
	type: clientType;
	operation: forecastDaxOperation;
	valueType: 'count' | 'amount' | 'percent' | 'value';
	value: number;
}