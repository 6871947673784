// CORE
import {useEffect} from "react";
import {useDispatch} from "react-redux"
import {useHistory, useLocation} from "react-router-dom";
// STORE
import admin from "../../app/store/reducers/admin";
// HOOKS
import {useAppSelector} from "./redux.hook";


const authRoutes = [
	'/password',
	'/brands',
	'/password-reset'
]

const useGetMe = () => {
	const auth = useAppSelector(state => state.admin.adminAuthed);
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		if (auth) return;

		(async () => {
			try{
				const response = await dispatch(admin.asyncActions.getMe())
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				if(response.meta.requestStatus === 'rejected'
					&& (!authRoutes.some(route => location.pathname.includes(route))
					 && location.pathname !== '/')
				) {
					history.push('/')
				}
			} catch (e) {
				history.push('/')
			}
		})()
	}, [auth, location.pathname]);

	return null;
}

export default useGetMe;
