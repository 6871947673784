// API
import {apiClient} from "../../clients/api.client";
// HELPERS
import getBrand, {isJigzoBrand} from "../../helpers/getBrand";
import objectToURL from "../../helpers/objectToURL";

// TYPES
import {IFeeMaintenanceCreate, IQueriesMaintenanceFees} from "../../store/reducers/maintenanceFees/index.D";


const {get, post, patch, delete: del, put} = apiClient;
const URL = () => `organizations/${getBrand(isJigzoBrand())}/maintenance-fees/`;

interface IUpdateMaintenanceFeePayload {
	id: string, data: Partial<IFeeMaintenanceCreate>
}

export default ({
	getMaintenanceFees:   (params: IQueriesMaintenanceFees) => get(`${URL()}?${objectToURL(params)}`),
	createMaintenanceFee: (data: IFeeMaintenanceCreate)=>post(URL(), data),
	updateMaintenanceFee: ({id, data}: IUpdateMaintenanceFeePayload) => patch(`${URL()}${id}`, data),
	deleteMaintenanceFee: (id: string) => del(`${URL()}${id}`),
})