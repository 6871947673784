// AXIOS
import {apiClient} from "../../clients/api.client";
// TYPES
import {IEditCustomer, IEditCustomerAddress} from "../../store/reducers/customers/index.D";
import {ICustomer} from "../../store/reducers/customer/index.D";
// HELPERS
import objectToURL from "../../helpers/objectToURL";
import getBrand from "../../helpers/getBrand"


const {get, patch, delete: del, put} = apiClient;
const URL = () => `organizations/${getBrand()}`;

export default ({
    getCustomerCards:
        (params?: { [key: string]: any }) => get(`${URL()}/customers/${params?.id}/card?${objectToURL(params)}`),
    get:                   (params?: { [key: string]: any }) => get(`${URL()}/customers?${objectToURL(params)}`),
    deleteCustomer:        (id: string) => del(`${URL()}/customers/${id}`),
    deleteManyCustomers:   (customerIds: ICustomer['_id'][]) => patch(`${URL()}/customers`, customerIds),
    updateCustomer:        ({_id, ...params}: IEditCustomer) => patch(`${URL()}/customers/${_id}`, params),
    updateCustomerRisk:    ({_id, ...params}: IEditCustomer) => put(`${URL()}/customers/${_id}/risk`, params),
    updateCustomerAddress: ({_id, data}: IEditCustomerAddress) => put(`${URL()}/customers/${_id}/address`, data),
});
