// CORE
import {createSlice} from '@reduxjs/toolkit';
// TYPES
import {ICryptoMarginsFeesSlice} from './index.D';
// ACTIONS
import asyncActions from './asyncActions';
import {defaultPagination} from "../search/pagination.D";


const initialState = {
	isLoading: false,
	platform:  { master: {
		items: [],
		info:  defaultPagination,
	}},
	global: {master: {
		items: [],
		info:  defaultPagination,
	}},
	pairs: {master: {
		items: [],
		info:  defaultPagination,
	}},
	balance: {master: {
		items: [],
		info:  defaultPagination,
	}},
	maintenance: {master: {
		items: [],
		info:  defaultPagination,
	}},
} as ICryptoMarginsFeesSlice;

const cryptoFeesMarginsSlice = createSlice({
	name:     'cryptoFeesMargins',
	initialState,
	reducers: {
		clearState: (state: ICryptoMarginsFeesSlice) => {
			state = initialState;

			return state;
		},
	},
	extraReducers: {
		[asyncActions.getPlatformFees.fulfilled.type]: (state: ICryptoMarginsFeesSlice, action) => {
			const { meta: { arg } } = action;
			const key = arg?.pricePlan
				? arg.pricePlan.toLowerCase()
				: arg?.basePriceTier
				? arg.basePriceTier.toLowerCase()
				: 'master';
			state.platform[key] = action.payload;
		},
		[asyncActions.getConversionGlobal.fulfilled.type]: (state: ICryptoMarginsFeesSlice, action) => {
			const { meta: { arg } } = action;
			const key = arg?.pricePlan
				? arg.pricePlan.toLowerCase()
				: arg?.basePriceTier
				? arg.basePriceTier.toLowerCase()
				: 'master';

			state.global[key] = action.payload;
		},
		[asyncActions.getConversionPairs.fulfilled.type]: (state: ICryptoMarginsFeesSlice, action) => {
			const { meta: { arg } } = action;
			const key = arg?.pricePlan
				? arg.pricePlan.toLowerCase()
				: arg?.basePriceTier
				? arg.basePriceTier.toLowerCase()
				: 'master';

			state.pairs[key] = action.payload;
		},

		[asyncActions.createPlatformFee.pending.type]: (state: ICryptoMarginsFeesSlice) => {
			state.isLoading = true;
		},
		[asyncActions.createPlatformFee.fulfilled.type]: (state: ICryptoMarginsFeesSlice) => {
			state.isLoading = false;
		},
		[asyncActions.createConversionGlobal.pending.type]: (state: ICryptoMarginsFeesSlice) => {
			state.isLoading = true;
		},
		[asyncActions.createConversionGlobal.fulfilled.type]: (state: ICryptoMarginsFeesSlice) => {
			state.isLoading = false;
		},
		[asyncActions.createConversionPair.pending.type]: (state: ICryptoMarginsFeesSlice) => {
			state.isLoading = true;
		},
		[asyncActions.createConversionPair.fulfilled.type]: (state: ICryptoMarginsFeesSlice) => {
			state.isLoading = false;
		},

		// GENERA:
		[asyncActions.updateMarginFee.pending.type]: (state: ICryptoMarginsFeesSlice) => {
			state.isLoading = true;
		},
		[asyncActions.updateMarginFee.fulfilled.type]: (state: ICryptoMarginsFeesSlice) => {
			state.isLoading = false;
		},
		[asyncActions.deleteMarginFee.pending.type]: (state: ICryptoMarginsFeesSlice) => {
			state.isLoading = true;
		},
		[asyncActions.deleteMarginFee.fulfilled.type]: (state: ICryptoMarginsFeesSlice) => {
			state.isLoading = false;
		},

		// REGULAR FEES
		[asyncActions.getBalanceFees.fulfilled.type]: (state: ICryptoMarginsFeesSlice, action) => {
			const { meta: { arg } } = action;
			const key = arg?.pricePlan
				? arg.pricePlan.toLowerCase()
				: arg?.basePriceTier
				? arg.basePriceTier.toLowerCase()
				: 'master';

			state.balance[key] = action.payload;
		},
		[asyncActions.getMaintenanceFees.fulfilled.type]: (state: ICryptoMarginsFeesSlice, action) => {
			const { meta: { arg } } = action;
			const key = arg?.pricePlan ? arg.pricePlan.toLowerCase() : 'master';

			state.maintenance[key] = action.payload;
		},
	},
});

export default ({
	...cryptoFeesMarginsSlice,
	asyncActions
})