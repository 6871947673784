import { apiClient } from "../../clients/api.client";
// HELPERS
import getBrand from "../../helpers/getBrand";
import objectToURL from "../../helpers/objectToURL";
// TYPES
import { ICreateSettingPayload } from '../../store/reducers/settings/index.D';


const { get, post, patch, delete: del } = apiClient;

const URL = () =>`organizations/${getBrand()}/transfer-settings`;

export default {
  createSetting: (data: ICreateSettingPayload) => post(`${URL()}`, data),
  getSettings:   (params: Record<string, string | number>) => get(`${URL()}/?${objectToURL(params)}`),
  updateSetting: ({ id, data }: { id: string; data: Partial<ICreateSettingPayload> }) => patch(`${URL()}/${id}`, data),
  deleteSetting: ({ id }: { id: string }) => del(`${URL()}/${id}`),
};
