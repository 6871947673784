// CORE
import { createAsyncThunk } from "@reduxjs/toolkit";
// HELPERS
import { storeAddErrorNotification } from "app/helpers/errors";
// SERVICE
import organizationSettingsService from "app/services/organizationSettings";
// TYPES
import { IUpdatePrimaryCurrency, OrganizationSettings } from "./index.D";


type PartialUpdate = Partial<OrganizationSettings>;

export default {
	getPrimaryCurrency: createAsyncThunk('organizationSettings/getPrimaryCurrency',
		async (_, thunkAPI) =>
			await storeAddErrorNotification(() => organizationSettingsService.getPrimaryCurrency(), thunkAPI)),
	updatePrimaryCurrency: createAsyncThunk('organizationSettings/updatePrimaryCurrency',
		async (data: IUpdatePrimaryCurrency, thunkAPI) =>
			await storeAddErrorNotification(() => organizationSettingsService.updatePrimaryCurrency(data), thunkAPI)),
	read: createAsyncThunk<OrganizationSettings>('organizationSettings/read',
		async (_, thunkAPI) =>
			await storeAddErrorNotification(() => organizationSettingsService.read(), thunkAPI)),
	updatePartial: createAsyncThunk<OrganizationSettings, PartialUpdate>('organizationSettings/updatePartial',
		async (data, thunkAPI) =>
			await storeAddErrorNotification(() => organizationSettingsService.updatePartial(data), thunkAPI)),
};