// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// HELPERS
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICES
import servicesBalances from "../../../services/balances"
// TYPES
import {IGetBalance, IDemoFundingAccount} from "./index.D";


export default {
	getCustomerBalances: createAsyncThunk('balances/getCustomerBalances',
		async (data:IGetBalance, thunkAPI) => await storeAddErrorNotification(
			() => servicesBalances.getCustomerBalances(data), thunkAPI, false)),
	getBusinessBalances: createAsyncThunk('balances/getBusinessBalances',
		async (data:IGetBalance, thunkAPI) => await storeAddErrorNotification(
			() => servicesBalances.getBusinessBalances(data), thunkAPI, false)),
	demoFundingAccount: createAsyncThunk('customers/customerDemoFunding',
		async (params: IDemoFundingAccount, thunkAPI) => await storeAddErrorNotification(
			() => servicesBalances.customerDemoFunding(params), thunkAPI, false))
		
};