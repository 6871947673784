// CORE
import { createSlice } from "@reduxjs/toolkit";
// SYNC ACTIONS
import asyncActions from "./asyncActions";
// CONSTANTS
import { defaultPagination } from "../search/pagination.D";
// TYPES
import { IPricePlansState } from "./index.D";


const initialState: IPricePlansState = {
  loading:    false,
  error:      null,
  pricePlans: {
    items: [],
    info:  defaultPagination,
  },
  pricePlansList: []
};

const pricePlansSlice = createSlice({
  name:     "pricePlans",
  initialState,
  reducers: {
    clearPricePlans: (state) => {
      state.pricePlans = {
        items: [],
        info:  defaultPagination,
      };
    },
    clearPricePlansList: (state) => {
      state.pricePlansList = [];
    },
  },
  extraReducers: {
    // CREATE
    [asyncActions.createPricePlan.pending.type]: (state) => {
        state.loading = true;
        state.error = null;
    },
    [asyncActions.createPricePlan.rejected.type]: (state, { payload }) => {
        state.loading = false;
        state.error = payload.message;
    },
    [asyncActions.createPricePlan.fulfilled.type]: (state) => {
        state.loading = false;
        state.error = null;
    },
    // READ
    [asyncActions.getPricePlans.pending.type]: (state) => {
        state.loading = true;
        state.error = null;
    },
    [asyncActions.getPricePlans.rejected.type]: (state, { payload }) => {
        state.loading = false;
        state.error = payload.message;
    },
    [asyncActions.getPricePlans.fulfilled.type]: (state, { payload }) => {
        state.loading = false;
        state.pricePlans = payload;
    },
    // GET LIST
    [asyncActions.getPricePlansList.pending.type]: (state) => {
        state.loading = true;
        state.error = null;
    },
    [asyncActions.getPricePlansList.rejected.type]: (state, { payload }) => {
        state.loading = false;
        state.error = payload.message;
    },
    [asyncActions.getPricePlansList.fulfilled.type]: (state, { payload }) => {
        state.loading = false;
        state.pricePlansList = payload;
    },
    // UPDATE
    [asyncActions.updatePricePlan.pending.type]: (state) => {
        state.loading = true;
        state.error = null;
    },
    [asyncActions.updatePricePlan.rejected.type]: (state, { payload }) => {
        state.loading = false;
        state.error = payload.message;
    },
    [asyncActions.updatePricePlan.fulfilled.type]: (state, { payload }) => {
        state.loading = false;
        state.pricePlans.items = state.pricePlans.items.map((item) => item._id !== payload._id ? item : payload);
    },
    // DELETE
    // [asyncActions.deletePricePlan.pending.type]: (state) => {
    //     state.loading = true;
    //     state.error = null;
    // },
    // [asyncActions.deletePricePlan.rejected.type]: (state, { payload }) => {
    //     state.loading = false;
    //     state.error = payload.message;
    // },
    // [asyncActions.deletePricePlan.fulfilled.type]: (state, { meta }) => {
    //     state.loading = false;
    //     state.pricePlans.items = state.pricePlans.items.filter(item => item._id !== meta.arg);
    // }
  }
});

export default ({
	...pricePlansSlice,
	asyncActions
})
