// CORE
import {createAsyncThunk} from '@reduxjs/toolkit';
// HELPERS
import {storeAddErrorNotification} from '../../../helpers/errors';
// SERVICES
import businessActivityService from "../../../services/businessActivity";
// TYPES
import {IBusinessActivity, IBusinessActivityCreate} from "./index.D";


export default ({
	getBusinessActivities: createAsyncThunk('businessActivity/get',
		async (params: undefined, thunkAPI) => await storeAddErrorNotification(
		() => businessActivityService.getBusinessActivities(), thunkAPI )),
	updateBusinessActivities: createAsyncThunk('businessActivity/update',
		async (data: IBusinessActivity, thunkAPI) => await storeAddErrorNotification(
			() => businessActivityService.updateBusinessActivity(data), thunkAPI )),
	createBusinessActivities: createAsyncThunk('businessActivity/create',
		async (data: IBusinessActivityCreate, thunkAPI) => await storeAddErrorNotification(
			() => businessActivityService.createBusinessActivity(data), thunkAPI )),

	getBusinessActivitiesOrg: createAsyncThunk('businessActivity/getOrg',
		async (params: undefined, thunkAPI) => await storeAddErrorNotification(
			() => businessActivityService.getBusinessActivitiesOrg(), thunkAPI )),
});