// AXIOS
import {apiClient} from "../../clients/api.client";
// TYPES
import {IOrganization, IOrganizationCreate, IQueriesOrganization} from '../../store/reducers/organizations/index.D'
// HELPERS
import objectToURL from "../../helpers/objectToURL";


const {get, put, post, delete: deleteApi } = apiClient;
const URL = 'organizations'

export default ({
	getOrganizations:        (id: string) => get(`${URL}/${id}/brands`),
	getOrganizationsByRole:  ({id, role}: {id: string, role: string}) => get(`${URL}/${id}/roles/${role}/brands`),
	getAllOrganizations:     () => get(`${URL}/list`),
	getOrganizationsByAdmin: (params: IQueriesOrganization) => get(`${URL}?${objectToURL(params)}`),
	getOrganization:         (id: string) => get(`${URL}/${id}`),
	createOrganization:      (data: IOrganizationCreate): Promise<IOrganization> => post(`${URL}`, data),
	updateOrganization:      ({_id, ...data}: IOrganization): Promise<IOrganization> => put(`${URL}/${_id}`, data),
	deleteOrganization:      (id: string): Promise<IOrganization> => deleteApi(`${URL}/${id}`),
	deployOrganization:      (id: string): Promise<IOrganization> => post(`${URL}/${id}/deploy-organization`),
});